import * as React from 'react'
import { format, parseISO } from 'date-fns'

import Base from '@/components/Layout/Base'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Table } from '@/components/Table'
import { AnchorButton } from '@/components/Button'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PreviewIcon from '@mui/icons-material/Preview'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import Tooltip from '@mui/material/Tooltip'
import type { Property } from '@/types/property'
import { User } from '@/types/user'
import * as PublicFileDetailAction from '@/actions/PublicFileDetailAction/'
import { redirect } from '@/utils/errors'

type Props = {
  property: Property
  public_file_details?: { [key: string]: string }
  current_user: User
  authenticity_token: string
}

const PublicFileDetails: React.FC<Props> = ({
  property,
  public_file_details,
  current_user,
  authenticity_token
}) => {

  const [PublicFileDetails, setPublicFileDetails] = React.useState(public_file_details)
  const [sortType, setSortType] = React.useState<sortTypeEnum>('date-desc')

  React.useEffect(() => {
    sortPublicFileDetails(sortType)
  }, [sortType])

  const sort = (columns: string[], direction: string) => {
    return direction == 'asc' ?
      columns[0] > columns[1] ? 1 : -1 :
      columns[0] < columns[1] ? 1 : -1
  }

  const sortPublicFileDetails = (sortType: sortTypeEnum) => {
    setPublicFileDetails(
      Array.from(PublicFileDetails).sort((n1, n2) => {
        const [column, direction] = sortType.split('-')
        switch(column) {
          case 'name':
            return sort([n1.property_document_format_name, n2.property_document_format_name], direction)
            break;
          case 'public_url':
            return sort([n1.public_url, n2.public_url], direction)
            break;
          case 'date':
            return sort([n1.created_at, n2.created_at], direction)
            break;
          default:
            return 0
        }
      })
    )
  }

  const copyPublicUrl = (index: number) => {
    navigator.clipboard.writeText(PublicFileDetails[index].fqdn_public_url)
  }

  const copyPassword = (index: number) => {
    navigator.clipboard.writeText(PublicFileDetails[index].password)
  }

  const deletePublicFileDetail = React.useCallback(
    async (id: number) => {
      const deleteItem = PublicFileDetails.find((PublicFileDetail) => PublicFileDetail.id === id)
      if (!confirm(deleteItem.name + 'を削除してもよろしいですか？' + deleteItem.public_file_detail_id && '\n 公開ページも削除されます')) {
        return
      }
      const params = { property_id: property.id, tab: 'public_files'}
      try {
        await PublicFileDetailAction.deletePublicFileDetail(id)
        const newPublicFileDetails = [...PublicFileDetails]
        const deleteIndex = PublicFileDetails.findIndex((PublicFileDetail) => PublicFileDetail.id === id)
        newPublicFileDetails.splice(deleteIndex, 1)
        setPublicFileDetails(newPublicFileDetails)
        const obj = new Object()
        redirect(obj, '/property_public_files/index_with_notice', '削除しました。', params)
      } catch(error) {
        redirect(error, '/property_public_files/index_with_notice', '削除に失敗しました。', params)
      }
    },
    [PublicFileDetails]
  )

  return (
    <div className="mt-10 mb-10">
      <div className="border-b-[1px] border-primary mb-2">
        <span className="inline-flex bg-primary text-white text-sm py-2 px-8">公開ページ</span>
      </div>
      <div className="text-right mb-2">
        <AnchorButton
          className="text-primary-font"
          prefix={<AddOutlinedIcon fontSize="small" />}
          outline
          size="small"
          variant="primary"
          href={`/property_public_files/select_formats?property_id=${property.hashid}`}
        >
          <span className="hidden md:inline">公開ページ追加</span>
        </AnchorButton>
      </div>
      <div className="mb-2">
        <Table borderLine={false}>
          <thead className="bg-gray-150 text-sm sticky top-0">
            <tr className="whitespace-nowrap">
              <th
                scope="col"
                className="py-2 px-4 font-medium cursor-pointer"
                onClick={() => {
                  sortType === 'name-asc' ? setSortType('name-desc') : setSortType('name-asc')
                }}
              >
                <div className={'flex'}>
                  <p className={'my-auto'}>物件概要書フォーマット名</p>
                  <div className={'w-6'}>
                    {sortType === 'name-asc' && <KeyboardArrowUpIcon />}
                    {sortType === 'name-desc' && <KeyboardArrowDownIcon />}
                  </div>
                </div>
              </th>
              <th
                scope="col"
                className="py-2 px-4 font-medium cursor-pointer"
                onClick={() => {
                  sortType === 'public_url-asc' ? setSortType('public_url-desc') : setSortType('public_url-asc')
                }}
              >
                <div className={'flex'}>
                  <p className={'my-auto'}>公開URL</p>
                  <div className={'w-6'}>
                    {sortType === 'public_url-asc' && <KeyboardArrowUpIcon />}
                    {sortType === 'public_url-desc' && <KeyboardArrowDownIcon />}
                  </div>
                </div>
              </th>
              <th
                scope="col"
                className="py-2 px-4 font-medium cursor-pointer"
                onClick={() => {
                  sortType === 'date-asc' ? setSortType('date-desc') : setSortType('date-asc')
                }}
              >
                <div className={'flex'}>
                  <p className={'my-auto'}>登録日時</p>
                  <div className={'w-6'}>
                    {sortType === 'date-asc' && <KeyboardArrowUpIcon />}
                    {sortType === 'date-desc' && <KeyboardArrowDownIcon />}
                  </div>
                </div>
              </th>
              <th scope="col" className="py-2 px-4 font-medium"></th>
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            {PublicFileDetails.map((PublicFileDetail, index) => (
              <tr key={index} className={`${index % 2 ? 'bg-gray-light' : 'bg-white'}`}>
                <td className="py-2 px-4">
                  {PublicFileDetail.property_document_format_name}
                </td>
                <td className="py-2 px-4">
                  {PublicFileDetail.public_status == "enabled" ?
                    <a href={PublicFileDetail.public_url} target="_blank">
                      {PublicFileDetail.public_url} <LaunchIcon fontSize="small" />
                    </a>:
                    PublicFileDetail.public_url
                  }
                </td>
                <td className="py-2 px-4">
                  {format(parseISO(PublicFileDetail.created_at), 'yyyy/MM/dd HH:mm:ss')}
                </td>
                <td className="py-2 px-4 text-right">
                  <a
                    className="inline-block w-[30px] hover:text-deleteHover"
                    href={`/property_public_files/${PublicFileDetail.hashid}/edit?property_id=${property.hashid}`}
                  >
                    <SettingsOutlinedIcon fontSize="small" />
                  </a>
                  {PublicFileDetail.public_status == "enabled" ?
                    <Tooltip title={<span>公開URLをコピー</span>}>
                      <span
                        className="inline-block w-[30px] hover:text-deleteHover cursor-pointer"
                        onClick={() => {
                          copyPublicUrl(index)
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </span>
                    </Tooltip>:
                    <span className="inline-block w-[30px] opacity-50">
                      <ContentCopyIcon fontSize="small" />
                    </span>
                  }
                  {PublicFileDetail.public_status == "enabled" && PublicFileDetail.password ?
                    <Tooltip title={<span>閲覧パスワードをコピー</span>}>
                      <span
                        className="inline-block w-[30px] hover:text-deleteHover cursor-pointer"
                        onClick={() => {
                          copyPassword(index)
                        }}
                      >
                        <LockOpenIcon fontSize="small" />
                      </span>
                    </Tooltip>:
                    <span className="inline-block w-[30px] opacity-50">
                      <LockOpenIcon fontSize="small" />
                    </span>
                  }
                  <Tooltip title={<span>プレビュー画面を開く</span>}>
                    <a
                      className="inline-block w-[30px] hover:text-deleteHover"
                      href={`/property_public_files/${PublicFileDetail.hashid}?property_id=${property.hashid}`}
                    >
                      <PreviewIcon fontSize="small" />
                    </a>
                  </Tooltip>
                  <span
                    className="inline-block w-[30px] hover:text-deleteHover cursor-pointer"
                    onClick={() => {
                      deletePublicFileDetail(PublicFileDetail.id)
                    }}
                  >
                    <DeleteOutlined fontSize="small" />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default PublicFileDetails
