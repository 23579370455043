import * as React from 'react'
import { format, formatDistanceStrict, parseISO } from 'date-fns'
import { ja } from 'date-fns/locale'

import { Table } from '@/components/Table'
import type { Property } from '@/types/property'
import type { File } from '@/types/file'

import { AnchorButton } from '@/components/Button'
import { buildingStructureType } from '@/config/langs/building_structure'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import { cammedFormat, formatNumber } from '@/utils/cammedFormat'
import Linkify from 'react-linkify'
import { componentDecorator } from './linkComponentDecorator'
import { unit_pct, unit_int_pct, unit_m2 } from '../../VolumeCheckRequests/volumeUnitFormat'
import {
  areaOfUseCodes,
  fireProtectionCodes,
  altitudeAreaCodes,
  shadeInfoSet,
} from '@/models/areaInfoCodes'
import Tooltip from '@mui/material/Tooltip'

const PropetyDetails: React.FC<{
  property: Property
  source_display: { [key: number]: boolean }
  excel_file?: File
  is_enable_edit_detail?: boolean
}> = ({ property, source_display, excel_file, is_enable_edit_detail }) => {
  const responseAreaData = property.area_info ? JSON.parse(property.area_info) : null
  const isPropertyLand = property?.property_type === '土地'
  return (
    <div className="mt-10 mb-10">
      <div className="border-b-[1px] border-primary mb-2">
        <span className="inline-flex bg-primary text-white text-sm py-2 px-8">物件詳細</span>
      </div>
      {excel_file && (
        <div className="mb-2">
          <Table borderLine={false}>
            <thead className="bg-gray-150 text-sm sticky top-0">
              <tr className="whitespace-nowrap">
                <th scope="col" className="py-2 px-4 font-medium">
                  ファイル名
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  登録日時
                </th>
                <th scope="col" className="py-2 px-4 font-medium"></th>
              </tr>
            </thead>
            <tbody className="whitespace-nowrap">
              <tr className="bg-white">
                <td className="py-2 px-4">
                  <a
                    href={excel_file.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary"
                  >
                    {excel_file.name}
                  </a>
                </td>
                <td className="py-2 px-4">
                  {format(parseISO(excel_file.created_at), 'yyyy/MM/dd HH:mm:ss')}
                </td>
                <td className="py-2 px-4 text-right">
                  <a
                    className="inline-block w-[30px] hover:text-deleteHover"
                    data-confirm={`\u0022${excel_file.name}\u0022を削除しますか？`}
                    rel="nofollow"
                    data-method="delete"
                    href={`/properties/${property.hashid}/detach_excel`}
                  >
                    <DeleteOutlined fontSize="small" />
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      {is_enable_edit_detail && (
        <div className="text-right mb-2">
          <AnchorButton
            className="text-primary-font"
            prefix={<BorderColorOutlinedIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            href={`/properties/${property.hashid}/edit?tab=property_details`}
          >
            <span className="hidden md:inline">物件編集</span>
          </AnchorButton>
        </div>
      )}
      <div>
        <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
          入手情報
        </div>
        <Table className="border-t border-b border-[#3885B0]" tag="div">
          <div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    入手先
                  </Th>
                }
              >
                <Td column={3}>
                  {property.ongoing_proposal ? source_display[property.ongoing_proposal.id] : ''}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    商談方式
                  </Th>
                }
              >
                <Td column={3}>{property.ongoing_proposal?.negotiation_method}</Td>
              </Row>
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    売主
                  </Th>
                }
              >
                <Td column={3}>{property.ongoing_proposal?.seller}</Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    入手日
                  </Th>
                }
              >
                <Td column={2}>
                  {property.ongoing_proposal &&
                    format(parseISO(property.ongoing_proposal.created_at), 'yyyy/MM/dd')}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    経過日数
                  </Th>
                }
              >
                <Td column={2}>
                  {property.ongoing_proposal &&
                    `${formatDistanceStrict(
                      new Date(),
                      new Date(property.ongoing_proposal.created_at),
                      {
                        locale: ja,
                        unit: 'day',
                      }
                    )}`}
                </Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    買付提出日
                  </Th>
                }
              >
                <Td column={3}>
                  {property.purchace_applied_at &&
                    format(parseISO(property.purchace_applied_at), 'yyyy/MM/dd')}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    仕入契約日
                  </Th>
                }
              >
                <Td column={3}>
                  {property.purchace_contract_at &&
                    format(parseISO(property.purchace_contract_at), 'yyyy/MM/dd')}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    仕入決済日
                  </Th>
                }
              >
                <Td column={3}>
                  {property.purchace_settlement_at &&
                    format(parseISO(property.purchace_settlement_at), 'yyyy/MM/dd')}
                </Td>
              </Row>
            </div>
            <Row label={<Th left={true}>備考</Th>}>
              <Td>
                <p className="whitespace-pre">
                  <Linkify componentDecorator={componentDecorator}>
                    {property?.ongoing_proposal?.note}
                  </Linkify>
                </p>
              </Td>
            </Row>
          </div>
        </Table>
      </div>
      {(property.instant_city_plannings.length > 0 || responseAreaData) && (
        <div className="mt-5">
          <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
            都市計画関係
          </div>
          <Table className="border-t border-b border-[#3885B0]">
            <div>
              <Row className="w-full" label={<Th left={true} column={1}></Th>}>
                <Td column={4}>領域1</Td>
                <Td column={4}>領域2</Td>
                <Td column={4}>領域3</Td>
                <Td column={4}>領域4</Td>
              </Row>
              {responseAreaData && (
                <>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        領域面積
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {unit_m2(responseAreaData.city_planning.merged_areas[0]?.['area'])}
                    </Td>
                    <Td column={4}>
                      {unit_m2(responseAreaData.city_planning.merged_areas[1]?.['area'])}
                    </Td>
                    <Td column={4}>
                      {unit_m2(responseAreaData.city_planning.merged_areas[2]?.['area'])}
                    </Td>
                    <Td column={4}>
                      {unit_m2(responseAreaData.city_planning.merged_areas[3]?.['area'])}
                    </Td>
                  </Row>
                </>
              )}
              <Row
                className="w-full"
                label={
                  <Th left={true} column={1}>
                    用途地域
                  </Th>
                }
              >
                {responseAreaData && (
                  <>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[0] !== undefined
                        ? areaOfUseCodes[
                            responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                              'youto_chiiki'
                            ]
                          ] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[1] !== undefined
                        ? areaOfUseCodes[
                            responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                              'youto_chiiki'
                            ]
                          ] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[2] !== undefined
                        ? areaOfUseCodes[
                            responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                              'youto_chiiki'
                            ]
                          ] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[3] !== undefined
                        ? areaOfUseCodes[
                            responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                              'youto_chiiki'
                            ]
                          ] || 'N/A'
                        : ''}
                    </Td>
                  </>
                )}
                {property.instant_city_plannings.length > 0 && responseAreaData === null && (
                  <>
                    <Td column={4}>
                      {property.instant_city_plannings[0] !== undefined
                        ? property.instant_city_plannings[0]?.['youto_chiiki'] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {property.instant_city_plannings[1] !== undefined
                        ? property.instant_city_plannings[1]?.['youto_chiiki'] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {property.instant_city_plannings[2] !== undefined
                        ? property.instant_city_plannings[2]?.['youto_chiiki'] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {property.instant_city_plannings[3] !== undefined
                        ? property.instant_city_plannings[3]?.['youto_chiiki'] || 'N/A'
                        : ''}
                    </Td>
                  </>
                )}
              </Row>
              {responseAreaData && (
                <>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        防火地域
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[0] !== undefined
                        ? fireProtectionCodes[
                            responseAreaData.city_planning.merged_areas[0]?.['fire_protection']?.[
                              'bouka_chiiki'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[1] !== undefined
                        ? fireProtectionCodes[
                            responseAreaData.city_planning.merged_areas[1]?.['fire_protection']?.[
                              'bouka_chiiki'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[2] !== undefined
                        ? fireProtectionCodes[
                            responseAreaData.city_planning.merged_areas[2]?.['fire_protection']?.[
                              'bouka_chiiki'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[3] !== undefined
                        ? fireProtectionCodes[
                            responseAreaData.city_planning.merged_areas[3]?.['fire_protection']?.[
                              'bouka_chiiki'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        高度地区
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[0] !== undefined
                        ? altitudeAreaCodes[
                            responseAreaData.city_planning.merged_areas[0]?.['altitude_area']?.[
                              'koudo_chiku'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[1] !== undefined
                        ? altitudeAreaCodes[
                            responseAreaData.city_planning.merged_areas[1]?.['altitude_area']?.[
                              'koudo_chiku'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[2] !== undefined
                        ? altitudeAreaCodes[
                            responseAreaData.city_planning.merged_areas[2]?.['altitude_area']?.[
                              'koudo_chiku'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[3] !== undefined
                        ? altitudeAreaCodes[
                            responseAreaData.city_planning.merged_areas[3]?.['altitude_area']?.[
                              'koudo_chiku'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        日影規制
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[0] !== undefined
                        ? shadeInfoSet(
                            responseAreaData.city_planning.merged_areas[0]?.['shadow_area']
                          ) || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[1] !== undefined
                        ? shadeInfoSet(
                            responseAreaData.city_planning.merged_areas[1]?.['shadow_area']
                          ) || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[2] !== undefined
                        ? shadeInfoSet(
                            responseAreaData.city_planning.merged_areas[2]?.['shadow_area']
                          ) || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[3] !== undefined
                        ? shadeInfoSet(
                            responseAreaData.city_planning.merged_areas[3]?.['shadow_area']
                          ) || 'N/A'
                        : ''}
                    </Td>
                  </Row>
                </>
              )}
              <Row
                className="w-full"
                label={
                  <Th left={true} column={1}>
                    建ぺい率
                  </Th>
                }
              >
                {responseAreaData && (
                  <>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                          'building_coverage_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                          'building_coverage_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                          'building_coverage_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                          'building_coverage_ratio'
                        ]
                      )}
                    </Td>
                  </>
                )}
                {property.instant_city_plannings.length > 0 && responseAreaData === null && (
                  <>
                    <Td column={4}>
                      {unit_int_pct(
                        property.instant_city_plannings[0]?.['building_coverage_ratio']
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        property.instant_city_plannings[1]?.['building_coverage_ratio']
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        property.instant_city_plannings[2]?.['building_coverage_ratio']
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        property.instant_city_plannings[3]?.['building_coverage_ratio']
                      )}
                    </Td>
                  </>
                )}
              </Row>
              <Row
                className="w-full"
                label={
                  <Th left={true} column={1}>
                    容積率
                  </Th>
                }
              >
                {responseAreaData && (
                  <>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                          'floor_area_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                          'floor_area_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                          'floor_area_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                          'floor_area_ratio'
                        ]
                      )}
                    </Td>
                  </>
                )}
                {property.instant_city_plannings.length > 0 && responseAreaData === null && (
                  <>
                    <Td column={4}>
                      {unit_int_pct(property.instant_city_plannings[0]?.['floor_area_ratio'])}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(property.instant_city_plannings[1]?.['floor_area_ratio'])}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(property.instant_city_plannings[2]?.['floor_area_ratio'])}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(property.instant_city_plannings[3]?.['floor_area_ratio'])}
                    </Td>
                  </>
                )}
              </Row>
              {responseAreaData && (
                <>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        採用建ぺい率（平均）
                      </Th>
                    }
                  >
                    <Td>
                      {unit_pct(responseAreaData.city_planning['adopted_building_area_ratio'])}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        採用容積率（平均）
                      </Th>
                    }
                  >
                    <Td>{unit_pct(responseAreaData.city_planning['adopted_floor_area_ratio'])}</Td>
                  </Row>
                </>
              )}
            </div>
          </Table>
          <div className="block text-xs px-4 pt-1 pb-2 whitespace-normal text-primary font-small">
            ※
            敷地面積、領域面積は小数点第3位切り捨てとし、敷地面積と各領域面積の合計が異なる場合があります。また、情報データが提供されていない地域の場合は、表内に"N/A"と表示されます。
          </div>
        </div>
      )}

      <div className="mt-5">
        <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
          事業情報
        </div>

        <Table className="border-t border-b border-[#3885B0]" tag="div">
          <div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    事業形態
                  </Th>
                }
              >
                <Td column={2}>{property.business_type?.name}</Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    現況
                  </Th>
                }
              >
                <Td column={2}>{property.local_situation}</Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    売主希望総額
                  </Th>
                }
              >
                <Td column={3}>
                  {property.ongoing_proposal?.suggested_total_price &&
                    `${formatNumber(property.ongoing_proposal.suggested_total_price)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    売主希望坪単価
                  </Th>
                }
              >
                <Td column={3}>
                  {property.ongoing_proposal?.suggested_unit_price &&
                    `${formatNumber(property.ongoing_proposal.suggested_unit_price)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    売主希望一種単価
                  </Th>
                }
              >
                <Td column={3}>
                  {property.ongoing_proposal?.suggested_primary_unit_price &&
                    isPropertyLand &&
                    `${formatNumber(property.ongoing_proposal.suggested_primary_unit_price)}円`}
                </Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    買取金額
                  </Th>
                }
              >
                <Td column={3}>
                  {property.ongoing_proposal?.purchase_price &&
                    `${formatNumber(property.ongoing_proposal.purchase_price)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    買取坪単価
                  </Th>
                }
              >
                <Td column={3}>
                  {property.ongoing_proposal?.purchase_unit_price &&
                    `${formatNumber(property.ongoing_proposal.purchase_unit_price)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    買取一種単価
                  </Th>
                }
              >
                <Td column={3}>
                  {property.ongoing_proposal?.purchase_primary_unit_price &&
                    isPropertyLand &&
                    `${formatNumber(property.ongoing_proposal.purchase_primary_unit_price)}円`}
                </Td>
              </Row>
            </div>
            <Row label={<Th left={true}>諸経費合計(仕入)</Th>}>
              <Td>
                {property.total_expense_cost_purchase &&
                  `${formatNumber(property.total_expense_cost_purchase)}円`}
              </Td>
            </Row>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    現況賃料（税込）/ 月額
                  </Th>
                }
              >
                <Td column={2}>
                  {property.current_rent && `${formatNumber(property.current_rent)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    <Tooltip
                      title={
                        <span>
                          買取金額が設定されている場合は、その「買取金額」で計算を行います。それ以外の場合は、「売主希望総額」を使用します。
                        </span>
                      }
                    >
                      <span className="cursor-help border-b border-dashed border-black">
                        現況表面利回り
                      </span>
                    </Tooltip>
                  </Th>
                }
              >
                <Td column={2}>
                  {property.current_prospective_yield &&
                    `${Number(property.current_prospective_yield).toFixed(2)}%`}
                </Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    現況NOI / 月額
                  </Th>
                }
              >
                <Td column={2}>
                  {property.net_income && `${formatNumber(property.net_income)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    現況NOI利回り
                  </Th>
                }
              >
                <Td column={2}>
                  {property.current_prospective_noi_yield &&
                    `${Number(property.current_prospective_noi_yield).toFixed(2)}%`}
                </Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    計画建ぺい率
                  </Th>
                }
              >
                <Td column={2}>{property.coverage_ratio && `${property.coverage_ratio}%`}</Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    計画容積率
                  </Th>
                }
              >
                <Td column={2}>{property.volume_rate && `${property.volume_rate}%`}</Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    棟数（区画）
                  </Th>
                }
              >
                <Td column={3}>
                  {property.number_of_buildings &&
                    `${formatNumber(property.number_of_buildings)}棟（区画）`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    戸数
                  </Th>
                }
              >
                <Td column={3}>
                  {property.number_of_units && `${formatNumber(property.number_of_units)}戸`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/3"
                label={
                  <Th left={true} column={3}>
                    仕入戸当たり単価
                  </Th>
                }
              >
                <Td column={3}>
                  {property.number_of_units &&
                    `${formatNumber(property.unit_price_per_unit_yield)}円`}
                </Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    建物構造
                  </Th>
                }
              >
                <Td column={2}>
                  {property.building_structure &&
                    buildingStructureType[property.building_structure]}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    建物階数
                  </Th>
                }
              >
                <Td column={2}>
                  {property.floors_above_ground && `地上 ${property.floors_above_ground} 階`}{' '}
                  {property.floors_under_ground && `地下 ${property.floors_under_ground} 階`}
                </Td>
              </Row>
            </div>
            <Row label={<Th left={true}>仕入延床坪単価</Th>}>
              <Td>
                {property.total_floor_area &&
                  `${cammedFormat(property.building_unit_price_yield, 0)}円`}
              </Td>
            </Row>
            <Row label={<Th left={true}>本体工事費</Th>}>
              <Td>
                {property.building_construction_cost &&
                  `${formatNumber(property.building_construction_cost)}円`}
              </Td>
            </Row>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    竣工日
                  </Th>
                }
              >
                <Td column={2}>
                  {property.build_completed_at &&
                    format(parseISO(property.build_completed_at), 'yyyy/MM/dd')}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    検査済証
                  </Th>
                }
              >
                <Td column={2}>{property.certificate_of_inspection}</Td>
              </Row>
            </div>
            <Row label={<Th left={true}>売上日</Th>}>
              <Td>{property.selled_at && format(parseISO(property.selled_at), 'yyyy/MM/dd')}</Td>
            </Row>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    販売土地価格
                  </Th>
                }
              >
                <Td column={2}>
                  {property.land_price && `${formatNumber(property.land_price)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    販売建物価格
                  </Th>
                }
              >
                <Td column={2}>
                  {property.building_price && `${formatNumber(property.building_price)}円`}
                </Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    税抜売上高
                  </Th>
                }
              >
                <Td column={2}>
                  {property.selling_price_without_tax &&
                    `${formatNumber(property.selling_price_without_tax)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    税込売上高
                  </Th>
                }
              >
                <Td column={2}>
                  {property.selling_price && `${formatNumber(property.selling_price)}円`}
                </Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    販売坪単価
                  </Th>
                }
              >
                <Td column={2}>
                  {property.selling_unit_price && `${formatNumber(property.selling_unit_price)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    販売一種単価
                  </Th>
                }
              >
                <Td column={2}>
                  {property.selling_primary_unit_price &&
                    `${formatNumber(property.selling_primary_unit_price)}円`}
                </Td>
              </Row>
            </div>
            <Row label={<Th left={true}>諸経費合計(販売)</Th>}>
              <Td>
                {property.total_expense_cost_price &&
                  `${formatNumber(property.total_expense_cost_price)}円`}
              </Td>
            </Row>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    事業収支
                  </Th>
                }
              >
                <Td column={2}>
                  {property.business_income && `${formatNumber(property.business_income)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    事業収支率
                  </Th>
                }
              >
                <Td column={2}>
                  {property.business_income_yield &&
                    `${Number(property.business_income_yield).toFixed(2)}%`}
                </Td>
              </Row>
            </div>
            <Row label={<Th left={true}>稼働状況</Th>}>
              <Td>
                {property.tenancy_rate &&
                  Number(property.tenancy_rate) !== 0 &&
                  `${(Number(property.tenancy_rate) * 10000) / 100}%`}
              </Td>
            </Row>
            <Row label={<Th left={true}>平均賃料（坪）</Th>}>
              <Td>{property.rent_per_tsubo && `${formatNumber(property.rent_per_tsubo)}円`}</Td>
            </Row>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    総額賃料（税込）/ 月額
                  </Th>
                }
              >
                <Td column={2}>
                  {property.total_rent && `${formatNumber(property.total_rent)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    販売表面利回り
                  </Th>
                }
              >
                <Td column={2}>
                  {property.prospective_yield &&
                    `${Number(property.prospective_yield).toFixed(2)}%`}
                </Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    想定NOI（税込）/ 月額
                  </Th>
                }
              >
                <Td column={2}>
                  {property.prospective_noi && `${formatNumber(property.prospective_noi)}円`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    販売想定NOI利回り
                  </Th>
                }
              >
                <Td column={2}>
                  {property.prospective_noi_yield &&
                    `${Number(property.prospective_noi_yield).toFixed(2)}%`}
                </Td>
              </Row>
            </div>
          </div>
        </Table>
      </div>

      <div className="mt-5">
        <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
          進捗メモ
        </div>
        <Table className="border-t border-b border-[#3885B0]">
          <div>
            {property.memos
              .toSorted((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
              .map((memo, index) => (
                <Row
                  key={index}
                  label={
                    <Th left={true} className={'w-32 md:w-32'}>
                      {format(parseISO(memo.updated_at), 'yyyy/MM/dd')}
                    </Th>
                  }
                >
                  <Td>
                    <p className="whitespace-pre">
                      {memo?.user?.name ?? '不明'} :{' '}
                      <Linkify componentDecorator={componentDecorator}>{memo.content}</Linkify>
                    </p>
                  </Td>
                </Row>
              ))}
          </div>
        </Table>
      </div>
    </div>
  )
}

export default PropetyDetails
