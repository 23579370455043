export const areaOfUseCodes = {
  1: '第１種低層住居専用地域',
  2: '第２種低層住居専用地域',
  3: '第１種中高層住居専用地域',
  4: '第２種中高層住居専用地域',
  5: '第１種住居地域',
  6: '第２種住居地域',
  7: '準住居地域',
  8: '近隣商業地域',
  9: '商業地域',
  10: '準工業地域',
  11: '工業地域',
  12: '工業専用地域',
  0: '指定なし',
}

export const fireProtectionCodes = {
  10: '防火地域',
  20: '準防火地域',
  0: '指定なし',
}

export const altitudeAreaCodes = {
  1: '第一種高度地区',
  2: '第ニ種高度地区',
  3: '第三種高度地区',
  4: '指定なし',
  0: '指定なし',
}

export const shadeInfoSet = (shadowAreaData: any) => {
  if (!shadowAreaData) {
    return ''
  }
  let shadowArea = {
    shade_5: shadowAreaData?.shade_5,
    shade_10: shadowAreaData?.shade_10,
    shade_height: shadowAreaData?.shade_height,
  }
  if (Object.values(shadowArea).every((value) => value === null)) {
    return ''
  } else if (Object.values(shadowArea).every((value) => value === 0)) {
    return '指定なし'
  } else {
    return `${shadowArea.shade_5?.toFixed(1)}h-${shadowArea.shade_10?.toFixed(
      1
    )}h GL+${shadowArea.shade_height?.toFixed(1)}m`
  }
}
