import '@/components/GoogleMapVolume/style.css'
import * as React from 'react'
import { GoogleMap } from '@react-google-maps/api'
import { Table } from '@/components/Table'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import { Button } from '@/components/Button'
import { Loading } from '@/components/Loading'
import type { MarketDatum } from '@/types/marketDatum'
import { marketDataSetup } from './marketDataSetup'
import { marketDataReportSetup } from './marketDataReportSetup'
import { usageAreaCode } from '@/models/property'
import { createRoot } from 'react-dom/client'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import StreetviewIcon from '@mui/icons-material/Streetview'
import RouteIcon from '@mui/icons-material/Route'
import ParkIcon from '@mui/icons-material/Park'
import { UserContext } from '@/components/Layout/Base'
import type { User } from '@/types/user'
import VolumeCheckChooseModal from '@/components/Page/VolumeCheckRequests/VolumeCheckChooseModal'
import KouzuMapModal from "@/components/Page/VolumeCheckRequests/KouzuMapModal";

type ModeType = 'volume' | 'volume_detail'
interface MapProps {
  polygons_api_base_url?: string
  csrfToken: string
  mode?: ModeType
  property_id?: number
  lat?: number | string
  lng?: number | string
  ref_volume_check_parameters?: any
  existdAreaValue
  areaTextFromResponse
  setResponseArea
  areaShape
  requestUUID
  onChangePolygon
  onChangeAreaCheck
  onRemovePolygon
  zoom?: number
  onChangeParameters
  onChangeShape
  style
  checkAreaButton?: React.ReactNode
  setDisabledGroup
  mapDivRef
  onChangeMapCenter
  current_user: User
  volume_check_detail
  plan_number
  authenticity_token
  setWidthLength
}
var markerRefresh = false

export interface MapHandles {
  setLocation(address: string, zoom?: number): void
  setMarketData(marketData: MarketDatum[]): void
  setMarketDataReport(marketData: MarketDatum[]): void
  clearMarketData(): void
}
export const GoogleMapVolume = React.forwardRef<MapHandles, MapProps>(
  (
    {
      polygons_api_base_url = '',
      csrfToken,
      mode,
      property_id,
      lat,
      lng,
      ref_volume_check_parameters,
      existdAreaValue,
      areaTextFromResponse,
      setResponseArea,
      areaShape,
      requestUUID,
      onChangePolygon,
      onChangeAreaCheck,
      onRemovePolygon,
      zoom,
      onChangeParameters,
      onChangeShape,
      style,
      checkAreaButton,
      setDisabledGroup,
      mapDivRef,
      onChangeMapCenter,
      current_user,
      volume_check_detail,
      plan_number,
      authenticity_token,
      setWidthLength,
    }: MapProps,
    ref
  ) => {
    const mapOptions = React.useMemo(() => {
      return {
        center: { lat: Number(lat), lng: Number(lng) },
        zoom: zoom,
        minZoom: 1,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: false,
        tilt: 0,
        mapTypeId: 'roadmap',
      }
    }, [lat, lng, mode])

    const [isOpen, setIsOpen] = React.useState(false)
    const [isOpenKouzu, setIsOpenKouzu] = React.useState(false)
    function closeModal() {
      setIsOpen(false)
    }
    function openModal() {
      setIsOpen(true)
    }
    function closeKouzuModal() {
      setIsOpenKouzu(false)
    }
    function openKouzuModal() {
      setIsOpenKouzu(true)
    }
    const { user } = React.useContext(UserContext)

    const [antifireInfo, setAntifireInfo] = React.useState('-')
    const [showInfoTable, setShowInfoTable] = React.useState(false)
    const [usageInfos, setUsageInfos] = React.useState({
      usageArea: '-',
      buildingCoverageRatio: '-',
      floorAreaRatio: '-',
    })
    const [heightInfos, setHeightInfos] = React.useState({
      heightInfo: '-',
      heightMax: '-',
      heightMin: '-',
    })
    const [shadeInfos, setShadeInfos] = React.useState({
      shade5m: '-',
      shade10m: '-',
      shadeHeight: '-',
    })
    const [districtInfos, setDistrictInfos] = React.useState({
      planName: '-',
    })
    const [marketDataMarkers, setMarketDataMarkers] = React.useState([])

    const mapRef = React.useRef(null)
    const loading = <Loading height={20} width={20} />
    const markerList = []
    const showAreaTextRef = React.useRef(null)
    let intervalId: number | null = null

    React.useImperativeHandle(ref, () => ({
      async setLocation(address, zoom) {
        const geocoder = new google.maps.Geocoder()
        const results = await geocoder.geocode({ address: address, language: 'ja' })
        mapRef?.current.setCenter(results.results[0].geometry.location)
        if (zoom !== undefined) {
          mapRef?.current.setZoom(zoom)
        }
      },
      setMarketData(
        marketData: MarketDatum[],
        clickMarker: (marketDatum: MarketDatum) => void,
        filteredMarketDatum: MarketDatum
      ) {
        // Mapに周辺事例のピンを表示する
        const markers = marketDataSetup(
          mapRef?.current,
          marketData,
          marketDataMarkers,
          clickMarker,
          filteredMarketDatum
        )

        // タブ切替時に表示していたピンのクリアを行うため保持しておく
        setMarketDataMarkers(markers)
      },
      setMarketDataReport(marketData: MarketDatum[]) {
        // Mapに周辺事例のピンを表示する
        const markers = marketDataReportSetup(mapRef?.current, marketData)

        // タブ切替時に表示していたピンのクリアを行うため保持しておく
        setMarketDataMarkers(markers)
      },
      clearMarketData() {
        // タブ切り替え前に表示していた周辺事例のピンをクリアする
        marketDataMarkers.forEach((marker) => {
          marker.setMap(null)
        })

        setMarketDataMarkers([])
      },
    }))
    const layerButtonStates = React.useRef({
      showPolygonsClicked: false,
      showKouzuMapClicked: false,
    })
    const cityPlanItems = ['youto_chiiki', 'bouka_chiiki', 'koudo_chiku', 'hikage_kisei', 'chiku_keikaku']
    const cadastralItems = ['kouzu_map']
    const infoMarkers = React.useRef([])
    const centerCursors = React.useRef([])
    const polygonsHash = React.useRef({
      youto_chiiki: {},
      bouka_chiiki: {},
      koudo_chiku: {},
      shikichi: {},
      hikage_kisei: {},
      chiku_keikaku: {},
      kouzu_map: {},
    })
    const chibanMarkers = React.useRef([])
    const selectedInfoWindows = React.useRef([])
    const isMapLoaded = React.useRef(false)
    const loadingIconFlg = React.useRef(null)
    const loadingIconFlg1 = React.useRef(null)
    const areaMarkers = React.useRef([])
    const areaText = React.useRef(null)


    const movingDistanceCtrled: number = 0.0000002;

    const movingDistanceUnCtrl: number = 0.0000002;
    let movingDistance: number = movingDistanceUnCtrl;

    const rotationAngleCtrled = Math.PI / 720;


    const rotationAngleUnCtrl = Math.PI / 720;
    let rotationAngle = rotationAngleUnCtrl;
    const scaleRatioUnCtrl = 0.002;
    let scaleRatio = scaleRatioUnCtrl;
    const scaleRatioCtrled = 0.002;
    let movePolygon: google.maps.Polygon;
    const onLoad = (map) => {
      mapRef.current = map
      centerControlInitialization(map);
      let currentPolygon = null // 図形のポリゴン


      let newProperty = null // 物件ピン

      const styledMapType = new google.maps.StyledMapType(
        [
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit.line', // 交通機関の路線
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
          {
            featureType: 'transit.station.rail', // 電車の駅
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
        ],
        { name: 'Styled Map' }
      )
      map.mapTypes.set('styled_map', styledMapType)
      let drawingManager

      map.setMapTypeId('styled_map')

      showAreaTextRef.current = showAreaText

      google.maps.event.addListener(map, 'tilesloaded', () => {
        isMapLoaded.current = true
      })
      google.maps.event.addListener(map, 'bounds_changed', () => {
        if (map.getZoom() < 17) {
          clearCityPlanItems()
          clearCadastralItems()
        } else {
          if (!layerButtonStates.current.showPolygonsClicked) {
            clearCityPlanItems()
          }

          if (!layerButtonStates.current.showKouzuMapClicked) {
            clearCadastralItems()
          }
        }
      })
      let initialLoad = true
      function parametersChanged() {
        if (mode != 'volume') {
          return
        }

        const params = {
          prefecture_name: (document.getElementById('prefecture_name') as HTMLInputElement).value,
          city_name: (document.getElementById('city_name') as HTMLInputElement).value,
          chome: (document.getElementById('chome') as HTMLInputElement).value,
          administrative_name: (document.getElementById('administrative_name') as HTMLInputElement)
            .value,
          center: {
            lat: Number((document.getElementById('center_lat') as HTMLInputElement).value),
            lng: Number((document.getElementById('center_lng') as HTMLInputElement).value),
          },
          borders: [],
          surroundings: [],
          building_setting: {
            max_height: 0,
            floors: [],
            floor_height: 0,
            gf_slab_level: 0,
            design_ground_level: 0,
          },
        }

        let next_i = 0
        const count_borders = parseInt(
          (document.getElementById('count_borders') as HTMLInputElement).value,
          10
        )
        let disabledGroupValue: { width: boolean; setback: boolean; surroundings: boolean }[] = []
        for (let i = 0; i < count_borders; i++) {
          let disabledGroupMap: { width: boolean; setback: boolean; surroundings: boolean } = {
            width: true,
            setback: true,
            surroundings: true,
          }
          if (i == count_borders - 1) {
            next_i = 0
          } else {
            next_i = i + 1
          }
          const from = (document.getElementById(`from_${i}`) as HTMLInputElement).value.split(',')
          const to = (document.getElementById(`to_${next_i}`) as HTMLInputElement).value.split(',')
          const border_type_code = (document.getElementById(`border_${i}`) as HTMLInputElement)
            .value
          let set_back_method = (document.getElementById(`setback_${i}`) as HTMLInputElement).value
          let width = (document.getElementById(`width_${i}`) as HTMLInputElement).value
          const height = '0'
          const surroundings_type = (
            document.getElementById(`surroundings_${i}`) as HTMLInputElement
          ).value
          let surroundings_width = (
            document.getElementById(`surroundings_${i}_width`) as HTMLInputElement
          ).value
          if (['1', '2', '3', '5', '6', '7'].indexOf(border_type_code) !== -1) {
            if (Number(width) === 0) {
              width = '6'
            }
          } else {
            width = '0'
            set_back_method = '0'
          }
          disabledGroupMap.width = true
          disabledGroupMap.setback = true
          if (['1', '2', '3'].indexOf(border_type_code) !== -1) {
            disabledGroupMap.setback = false
            disabledGroupMap.width = false
          } else if (['5', '6', '7'].indexOf(border_type_code) !== -1) {
            disabledGroupMap.width = false
            set_back_method = '0'
          }
          disabledGroupMap.surroundings = false
          params.borders.push({
            id: i + 1,
            from: { lat: Number(from[0]), lng: Number(from[1]), height: Number(height) },
            to: { lat: Number(to[0]), lng: Number(to[1]) },
            border_type_code: Number(border_type_code),
            set_back_method: Number(set_back_method),
            width: Number(width),
          })
          params.surroundings.push({
            border_id: i + 1,
            border_type_code: Number(surroundings_type),
            width: Number(surroundings_width),
          })

          disabledGroupValue.push(disabledGroupMap)
          setDisabledGroup(disabledGroupValue)
        }
        params.building_setting = {
          max_height: Number((document.getElementById(`max_height`) as HTMLInputElement).value),
          floors: [],
          floor_height: Number(
            (document.getElementById(`max_floor_height`) as HTMLInputElement).value
          ),
          gf_slab_level: Number(
            (document.getElementById(`gf_slab_level`) as HTMLInputElement).value
          ),
          design_ground_level: Number(
            (document.getElementById(`design_ground_level`) as HTMLInputElement).value
          ),
        }
        let floors_above_ground = Number(
          (document.getElementById(`floors_above_ground`) as HTMLInputElement).value
        )
        if (floors_above_ground === 0) {
          floors_above_ground = 5
        }
        const floors_under_ground = Number(
          (document.getElementById(`floors_under_ground`) as HTMLInputElement).value
        )
        let max_floor_height = Number(
          (document.getElementById(`max_floor_height`) as HTMLInputElement).value
        )
        if (max_floor_height === 0) {
          max_floor_height = 3
        }
        for (let i = 0; i < floors_above_ground; i++) {
          params.building_setting.floors.push({
            floor_number: i + 1,
            max_floor_height: max_floor_height,
          })
        }
        for (let i = 0; i < floors_under_ground; i++) {
          params.building_setting.floors.push({
            floor_number: i - 1,
            max_floor_height: max_floor_height,
          })
        }
        ;(document.getElementById('volume_check_request_parameters') as HTMLInputElement).value =
          JSON.stringify(params)
      }
      function addMarker(
        property: {
          id: number
          lat: number
          lng: number
          property_type?: string
        },
        map,
        tag_id: number = undefined,
        tag_color: number = undefined
      ) {
        const marker_params = {
          position: new google.maps.LatLng(Number(property.lat), Number(property.lng)),
          map: map,
          optimized: false,
        }
        if (mode == 'volume') {
          marker_params['draggable'] = true
        }
        const marker = new google.maps.Marker(marker_params)

        if (mode === 'volume') {
          let icon
          if (property_id == property.id) {
            icon = {
              url: '/target_ping.png',
              scaledSize: new google.maps.Size(37, 30),
            }
            marker.setOptions({ zIndex: 99999 })
          } else {
            marker.setVisible(false)
          }
          marker.setIcon(icon)
        }

        if (mode == 'volume' && isMapLoaded.current) {
          marker.addListener('dragend', () => {
            const lat = marker.getPosition().lat()
            const lng = marker.getPosition().lng()
            fetch('/api/property_latlng_update', {
              method: 'PATCH',
              body: JSON.stringify({
                id: property.id,
                lat: lat,
                lng: lng,
              }),
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken,
              },
            }).catch((err) => {
              console.log(err)
            })
          })
        }
        return marker
      }
      async function createParameters(polygon = null) {
        if (mode != 'volume') return
        const refVolume = new URLSearchParams(window.location.search).has('ref')

        if (polygon) {
          const res = await fetch(`/properties/${property_id}/volume_check_requests/property_json`)
          const property = await res.json()
          if (onChangeParameters) {
            onChangeParameters(property)
          }

          // DOMの描画完了を待つ
          await new Promise((resolve) => setTimeout(resolve))

          let params = JSON.parse(
            (document.getElementById('volume_check_request_parameters') as HTMLInputElement)
              .value || '{ "borders": [], "surroundings": [], "building_setting": {} }'
          )

          if (refVolume && initialLoad) {
            params = JSON.parse(ref_volume_check_parameters)
            initialLoad = false
          }

          const path = []
          polygon.getPath().forEach((latlng) => {
            path.push(latlng)
          })
          let next_i = 0
          for (let i = 0; i < path.length; i++) {
            if (i == path.length - 1) {
              next_i = 0
            } else {
              next_i = i + 1
            }
            const distance = google.maps.geometry.spherical.computeDistanceBetween(
              path[i],
              path[next_i]
            )
            ;(document.getElementById(`length_${i}`) as HTMLInputElement).value = `${
              Math.round(distance * 100) / 100
            }m`
            ;(document.getElementById(`border_${i}`) as HTMLInputElement).value =
              (i in params.borders && params.borders[i].border_type_code) || '4'
            ;(document.getElementById(`width_${i}`) as HTMLInputElement).value =
              (i in params.borders && params.borders[i].width) || ''
            ;(document.getElementById(`setback_${i}`) as HTMLInputElement).value =
              (i in params.borders && params.borders[i].set_back_method) || '1'
            ;(document.getElementById(`height_${i}`) as HTMLInputElement).value =
              (i in params.borders && params.borders[i].from.height) || '0'
            ;(document.getElementById(`surroundings_${i}`) as HTMLInputElement).value =
              (i in params.surroundings && params.surroundings[i].border_type_code) || '4'
            ;(document.getElementById(`surroundings_${i}_width`) as HTMLInputElement).value =
              (i in params.surroundings && params.surroundings[i].width) || ''
          }
        } else {
          if (onChangeParameters) {
            onChangeParameters(null)
          }
          ;(document.getElementById('volume_check_request_parameters') as HTMLInputElement).value =
            ''
        }
        parametersChanged()
      }
      if (mode === 'volume') {
        drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: null, // PAN
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
          //ポリゴンのオプション
          polygonOptions: {
            draggable: true,
            fillColor: '#55ee55',
            fillOpacity: 0.5,
            strokeWeight: 2,
            clickable: true,
            editable: true,
            zIndex: 1,
          },
        })
        drawingManager.setMap(map)

        google.maps.event.addListener(drawingManager, 'polygoncomplete', function (polygon) {
          showAreaText(polygon)
          showAreaMarkers(polygon)
          if (mode == 'volume') {
            const shape = []
            polygon.getPath().forEach((latlng) => {
              shape.push({ lat: latlng.lat(), lng: latlng.lng() })
            })
            onChangeShape(JSON.stringify(shape))
            getDistance(polygon)
            onChangePolygon(true)
            if (onChangePolygon) {
              onChangePolygon(true)
            }
            if (onChangeAreaCheck) {
              onChangeAreaCheck(false)
            }

            google.maps.event.addListener(polygon, 'mouseup', () => {
              showAreaText(polygon)
              showAreaMarkers(polygon)
              const shape = []
              polygon.getPath().forEach((latlng) => {
                shape.push({ lat: latlng.lat(), lng: latlng.lng() })
              })
              onChangeShape(JSON.stringify(shape))
              getDistance()
              if (onChangePolygon) {
                onChangePolygon(true)
              }
              if (onChangeAreaCheck) {
                onChangeAreaCheck(false)
              }
            })
            if (!(currentPolygon === null)) {
              currentPolygon.setMap()
            }
            currentPolygon = polygon
            drawingManager.setDrawingMode(null)
            void createParameters(polygon)
            movePolygon = currentPolygon
          }
        })
        google.maps.event.addListener(drawingManager, 'dragstart', function (polygon) {
          setResponseArea('　')
        })
        const deleteButton = document.createElement('button')

        deleteButton.textContent = '描画削除'
        deleteButton.classList.add('custom-map-control-button')
        deleteButton.setAttribute('type', 'button')
        deleteButton.style.fontSize = '14px'
        deleteButton.style.margin = '4px 8px'
        if (mode == 'volume') deleteButton.style.marginTop = '4em'

        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(deleteButton)

        deleteButton.addEventListener('click', () => {
          if (onChangePolygon) {
            onChangePolygon(false)
          }
          if (onChangeAreaCheck) {
            onChangeAreaCheck(false)
          }
          if (currentPolygon === null) return
          currentPolygon.setMap()
          currentPolygon = null
          areaText.current.setMap()
          areaText.current = null
          showAreaMarkers()
          onChangeShape('')
          setWidthLength([])
          if (mode === 'volume') {
            void createParameters()
            onRemovePolygon(true)
          }
        })
      }
      function createLayerButtons(
        type,
        imageSource,
        buttonText,
        buttonFunction,
        svgAttributes = {}
      ) {
        const button = document.createElement('button')
        button.classList.add('custom-map-control-button')
        button.setAttribute('type', 'button')
        button.style.fontSize = '14px'
        button.style.margin = '4px 4px 0 0'
        button.style.color = 'black'
        if (type === 'svg') {
          const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
          Object.entries(svgAttributes).forEach(([key, value]) => {
            svg.setAttribute(key, String(value))
          })
          const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
          path.setAttribute('d', imageSource)
          svg.appendChild(path)
          button.appendChild(svg)
        } else if (type === 'img') {
          const img = document.createElement('img')
          img.src = imageSource
          img.height = 15
          img.width = 15
          img.style.display = 'inline-block'
          img.style.verticalAlign = 'middle'
          img.style.marginRight = '5px'
          button.appendChild(img)
        }
        const span = document.createElement('span')
        span.textContent = buttonText
        button.appendChild(span)
        button.addEventListener('click', buttonFunction)
        return button
      }
      if (user.pricing_type !== 'basic' && mode != 'volume_detail') {
        const layerButtonSvgPath =
          'm11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z'
        const svgAttributes = {
          class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
          focusable: 'false',
          'aria-hidden': 'true',
          viewBox: '0 0 24 24',
        }
        const layerButton = createLayerButtons(
          'svg',
          layerButtonSvgPath,
          'レイヤー',
          () => {
            document.querySelectorAll('.polygons-button').forEach((elem) => {
              ;(elem as HTMLElement).style.display =
                (elem as HTMLElement).style.display === 'none' ? 'block' : 'none'
            })
          },
          svgAttributes
        )
        layerButton.id = 'layerButton'
        layerButton.style.display = 'none'
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(layerButton)
        const usageSvgPath =
          'M17 11V3H7v4H3v14h8v-4h2v4h8V11h-4zM7 19H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm4 4H9v-2h2v2zm0-4H9V9h2v2zm0-4H9V5h2v2zm4 8h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2z'
        const usageButtonFunction = () => {
          const newLayerButtonStates = {
            ...layerButtonStates.current,
            showPolygonsClicked: !layerButtonStates.current.showPolygonsClicked,
          }
          handleLayerButtonStatesChange(newLayerButtonStates)

          if (!newLayerButtonStates.showPolygonsClicked) {
            usageButton.style.color = 'black'
            document.getElementById('youto_chiiki').style.color = '#0A689D'
            setShowInfoTable(false)
            map.setOptions({
              draggable: true,
              scrollwheel: true,
              disableDoubleClickZoom: false,
              zoomControl: true,
              scaleControl: true,
              panControl: true,
              minZoom: 0,
              maxZoom: 20,
            })
          } else {
            usageButton.style.color = 'red'
            document.getElementById('youto_chiiki').style.color = 'red'
          }
        }
        const usageButton = createLayerButtons(
          'svg',
          usageSvgPath,
          '用途地域',
          usageButtonFunction,
          usageSvgAttributes
        )
        usageButton.id = 'usageButton'
        usageButton.classList.add('polygons-button')
        usageButton.style.display = 'none'
        usageButton.style.color = layerButtonStates.current.showPolygonsClicked ? 'red' : 'black'
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(usageButton)

        // if (user.pricing_type === 'owner' && user.role === 'system_admin') {
        //   const kouzuMapSvgAttributes = {
        //     class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
        //     focusable: 'false',
        //     'aria-hidden': 'true',
        //     viewBox: '0 0 25 28',
        //   }
        //   const kouzuMapSvgPath =
        //     'M3 21h2v-2H3v2zm4 0h2v-2H7v2zM5 7H3v2h2V7zM3 17h2v-2H3v2zM9 3H7v2h2V3zM5 3H3v2h2V3zm12 0h-2v2h2V3zm2 6h2V7h-2v2zm0-6v2h2V3h-2zm-4 18h2v-2h-2v2zM13 3h-2v8H3v2h8v8h2v-8h8v-2h-8V3zm6 18h2v-2h-2v2zm0-4h2v-2h-2v2z'
        //   const kouzuMapButtonFunction = () => {
        //     let newLayerButtonStates = {
        //       ...layerButtonStates.current,
        //       showKouzuMapClicked: !layerButtonStates.current.showKouzuMapClicked,
        //     }
        //     handleLayerButtonStatesChange(newLayerButtonStates)
        //
        //     kouzuMapButton.style.display = 'block'
        //     if (newLayerButtonStates.showKouzuMapClicked) {
        //       kouzuMapButton.style.color = 'red'
        //       document.getElementById('kouzuMapButton').style.color = 'red'
        //     } else {
        //       kouzuMapButton.style.color = 'black'
        //       document.getElementById('kouzuMapButton').style.color = '#0A689D'
        //     }
        //   }
        //   const kouzuMapButton = createLayerButtons(
        //     'svg',
        //     kouzuMapSvgPath,
        //     '公図情報',
        //     kouzuMapButtonFunction,
        //     kouzuMapSvgAttributes
        //   )
        //   kouzuMapButton.id = 'kouzuMapButton1'
        //   kouzuMapButton.classList.add('polygons-button')
        //   kouzuMapButton.style.display = 'none'
        //   map.controls[google.maps.ControlPosition.RIGHT_TOP].push(kouzuMapButton)
        // }
      }
      if (mode === 'volume') {
        let fetchURL = `/properties/${property_id}/shape`
        if (mode == 'volume') {
          let currentSearchParams = new URLSearchParams(window.location.search)
          if (currentSearchParams.has('ref')) {
            let fetchParams = new URLSearchParams({ ref: currentSearchParams.get('ref') })
            fetchURL += `?${fetchParams.toString()}`
          }
        }
        fetch(fetchURL)
          .then((res) => res.json())
          .then((json) => {
            if (json.status == 'NG') return

            const polygon = new google.maps.Polygon({
              paths: json.shape.map((latlng) => {
                return new google.maps.LatLng(latlng.lat, latlng.lng)
              }),
              draggable: true,
              fillColor: '#55ee55',
              fillOpacity: 0.5,
              strokeWeight: 2,
              clickable: mode === 'volume',
              editable: mode === 'volume',
              zIndex: 1,
            })
            polygon.setMap(map)
            showAreaMarkers(polygon)
            const shape = []
            polygon.getPath().forEach((latlng) => {
              shape.push({ lat: latlng.lat(), lng: latlng.lng() })
            })
            // reset map center
            // const newCenterLatLng = new google.maps.LatLng(shape[0].lat, shape[0].lng);
            // map.setCenter(newCenterLatLng);
            onChangeShape(JSON.stringify(shape))
            movePolygon = polygon;
            getDistance()
            if (mode == 'volume') {
              google.maps.event.addListener(polygon, 'mouseup', () => {
                showAreaText(polygon)
                showAreaMarkers(polygon)
                void createParameters(polygon)
                const shape = []
                polygon.getPath().forEach((latlng) => {
                  shape.push({ lat: latlng.lat(), lng: latlng.lng() })
                })
                onChangeShape(JSON.stringify(shape))
                getDistance()
                if (onChangePolygon) {
                  onChangePolygon(true)
                }
                if (onChangeAreaCheck) {
                  onChangeAreaCheck(false)
                }
              })
              drawingManager.setDrawingMode(null)
            }
            currentPolygon = polygon
            showAreaText(currentPolygon)
            void createParameters(polygon)
          })
          .catch((err) => {
            console.log(err)
          })
      }
      let params: any = {}

      google.maps.event.addListener(map, 'idle', () => {
        map.setCenter(map.getCenter())
        const latlngBounds = map.getBounds()
        const swLatlng = latlngBounds.getSouthWest()
        const neLatlng = latlngBounds.getNorthEast()
        if (
          params &&
          params.lat1 == swLatlng.lat() &&
          params.lng1 == swLatlng.lng() &&
          params.lat2 == neLatlng.lat() &&
          params.lng2 == neLatlng.lng()
        ) {
          if (!markerRefresh) return
        }
        params = {
          lat1: swLatlng.lat(),
          lng1: swLatlng.lng(),
          lat2: neLatlng.lat(),
          lng2: neLatlng.lng(),
          with_shape: map.getZoom() >= 17,
        }
        if (mode === 'volume') {
          if (markerList.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;[...markerList].forEach((marker) => {
              marker.setMap()
            })
            markerList.length = 0
          }
          const query = new URLSearchParams(params)
          fetch(`/api/properties?${query}`)
            .then((res) => res.json())
            .then((json) => {
              json.res.forEach((marker) => {
                if (marker.lat < 0 || marker.lng < 0) return
                // マーカーを追加
                markerList.push(addMarker(marker, map, marker.tag_id, marker.tag_color))
              })
            })
            .catch((err) => {
              console.log(err)
            })
          markerRefresh = false
        }
        if (map.getZoom() >= 17) {
          if (
            layerButtonStates.current.showPolygonsClicked ||
            layerButtonStates.current.showKouzuMapClicked
          ) {
            renderPolygons()
          }

          if (layerButtonStates.current.showPolygonsClicked) {
            setShowInfoTable(true)
          } else {
            clearCityPlanItems()
            setShowInfoTable(false)
          }
          if (!layerButtonStates.current.showKouzuMapClicked) {
            clearCadastralItems()
          }
        } else {
          setShowInfoTable(false)
          clearCityPlanItems()
          clearCadastralItems()
        }
        const center = map.getCenter()
        onChangeMapCenter({lat: center.lat(), lng: center.lng()})
      })
      fullscreenHandler()
    }

    function fullscreenHandler() {
      const elementToSendFullscreen = mapDivRef.current
      document.onwebkitfullscreenchange =
        document.onmsfullscreenchange =
        document.onmozfullscreenchange =
        document.onfullscreenchange =
          function () {
            if (isFullscreen(elementToSendFullscreen)) {
              document.getElementById('layerButton').style.display = 'block'
            } else {
              document.getElementById('layerButton').style.display = 'none'
              document.querySelectorAll('.polygons-button').forEach((elem) => {
                ;(elem as HTMLElement).style.display = 'none'
              })
            }
          }
    }

    function showAreaMarkers(polygon = null) {
      areaMarkers.current.forEach((marker) => {
        marker.setMap()
      })
      areaMarkers.current.length = 0
      if (polygon) {
        let i = 1
        polygon.getPath().forEach((latlng) => {
          areaMarkers.current.push(
              new google.maps.Marker({
                position: latlng,
                map: mapRef.current,
                label: {
                  text: `${i++}`,
                  color: '#ffffff',
                  fontWeight: 'bold',
                  fontSize: '14',
                },
                icon: {
                  url: '/map_flag.png',
                  anchor: new google.maps.Point(2, 30),
                  labelOrigin: new google.maps.Point(8, 9),
                  scaledSize: new google.maps.Size(15, 30),
                },
                optimized: false,
              })
          )
        })
      }
    }

    function showAreaText(polygon, areaValue = '') {
      const bounds = new google.maps.LatLngBounds()
      let area
      let labelColor = '#691a11'
      if (areaValue) {
        if (!polygon) {
          return
        }
        if (areaValue.endsWith('gmap')) {
          areaValue = areaValue.replace('gmap', '㎡')
          labelColor = '#0000ff'
        }
        const polygonShape = JSON.parse(polygon)
        const parsedShape = polygonShape.map((coord) => {
          return {
            lat: Number(coord.lat),
            lng: Number(coord.lng),
          }
        })
        parsedShape.forEach((latlng) => {
          bounds.extend(latlng)
        })
      } else {
        const roundedPath = []
        polygon.getPath().forEach((latlng) => {
          bounds.extend(latlng)

          roundedPath.push({
            lat: Math.round(latlng.lat() * 1000000) / 1000000,
            lng: Math.round(latlng.lng() * 1000000) / 1000000,
          })
        })
        area = google.maps.geometry.spherical.computeArea(roundedPath)
      }
      if (areaText.current) {
        areaText.current.setMap()
      }
      areaText.current = new google.maps.Marker({
        position: bounds.getCenter(),
        map: mapRef.current,
        icon: {
          url: '',
          size: new google.maps.Size(1, 1),
        },
        label: {
          text: areaValue || ' ',
          color: labelColor,
          fontFamily: 'sans-serif',
          fontWeight: 'bold',
          fontSize: '14px',
        },
      })
    }

    function isFullscreen(element: HTMLElement) {
      return (
        (document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement) == element
      )
    }

    function clearItems(areas) {
      areas.forEach((area) => {
        Object.keys(polygonsHash.current[area]).forEach((key) => {
          polygonsHash.current[area][key].setMap()
          delete polygonsHash.current[area][key]
        })
      })
    }

    function clearCityPlanItems() {
      clearItems(cityPlanItems)
      infoMarkers.current.forEach((infoMarker) => {
        infoMarker.setMap(null)
      })
      centerCursors.current.forEach((centerCursor) => {
        centerCursor.setMap(null)
      })
      setUsageInfos({
        usageArea: '-',
        buildingCoverageRatio: '-',
        floorAreaRatio: '-',
      })
      setAntifireInfo('-')
      setHeightInfos({
        heightInfo: '-',
        heightMax: '-',
        heightMin: '-',
      })
      setShadeInfos({
        shade5m: '-',
        shade10m: '-',
        shadeHeight: '-',
      })
      setDistrictInfos({
        planName: '-',
      })
    }
    function centerCursor() {
      let centerCursor = new google.maps.Marker({
        position: mapRef.current.getCenter(),
        map: mapRef.current,
        icon: {
          url: '/center_cursor.png',
          scaledSize: new google.maps.Size(35, 35),
        },
      })
      centerCursors.current.push(centerCursor)
    }

    function createLoadingIcon(buttonId, iconId) {
      const button = document.getElementById(buttonId)
      if (!button) return

      let loadingIconContainer = document.createElement('span')
      loadingIconContainer.id = iconId
      loadingIconContainer.style.display = 'inline-block'
      loadingIconContainer.style.marginLeft = '5px'
      button.appendChild(loadingIconContainer)

      let loadingRoot = createRoot(loadingIconContainer)
      loadingRoot.render(<Loading height={12} width={12} />)

      return loadingRoot
    }

    function removeLoadingIcon(loadingRoot, iconId) {
      let loadingIconContainer = document.getElementById(iconId)
      if (loadingIconContainer) {
        loadingRoot.unmount()
        loadingIconContainer.parentNode.removeChild(loadingIconContainer)
      }
    }
    function displayLayerMarker(
      zoomLevel,
      markerSize,
      fontSize,
      contentFunc,
      markerArray,
      data,
      center,
      iconURL
    ) {
      if (mapRef.current.getZoom() >= zoomLevel) {
        const contentString = contentFunc(data)
        if (typeof contentString === 'undefined') {
          return
        }
        let marker = new google.maps.Marker({
          position: center,
          map: mapRef.current,
          icon: {
            url: iconURL,
            scaledSize: new google.maps.Size(markerSize[0], markerSize[1]),
          },
          label: {
            text: contentString,
            color: '#125690',
            fontSize: fontSize,
            fontWeight: 'bold',
          },
          clickable: false,
        })
        markerArray.push(marker)
      }
    }
    function labelContent(data: any) {
      const acronym = {
        '1': '1低',
        '2': '2低',
        '3': '1中',
        '4': '2中',
        '5': '1住',
        '6': '2住',
        '7': '準住',
        '8': '近商',
        '9': '商業',
        '10': '準工',
        '11': '工業',
        '12': '工専',
        '0': '-',
      }
      let content = `${acronym[data.types.youto_chiiki]}:${data.types.building_coverage_ratio}:${
        data.types.floor_area_ratio
      }`
      return content
    }
    function displayInfoMarker(data, center) {
      displayLayerMarker(
        17,
        [130, 60],
        '12px',
        labelContent,
        infoMarkers.current,
        data,
        center,
        '/info_label.png'
      )
    }
    function getUsageInfo(data) {
      if (data.types.youto_chiiki == 0) {
        const content = {
          usageArea: 'N/A',
          buildingCoverageRatio: 'N/A',
          floorAreaRatio: 'N/A',
        }
        setUsageInfos(content)
      } else {
        const content = {
          usageArea: usageAreaCode[data.types.youto_chiiki],
          buildingCoverageRatio: `${data.types.building_coverage_ratio}%`,
          floorAreaRatio: `${data.types.floor_area_ratio}%`,
        }
        setUsageInfos(content)
      }
    }
    function getAntifireInfo(data) {
      const acronym = { '10': '防火地域', '20': '準防火地域', '0': '指定なし' }
      let content = acronym[data.types.bouka_chiiki]
      setAntifireInfo(content)
    }
    function getHeightInfo(data) {
      if (data.types.koudo_chiku == 0) {
        const content = {
          heightInfo: '指定なし',
          heightMax: '指定なし',
          heightMin: '指定なし',
        }
        setHeightInfos(content)
      } else if (data.types.koudo_chiku == 4) {
        const content = {
          heightInfo: '指定なし',
          heightMax: data.types.max_height == 0 ? '指定なし' : `${data.types.max_height}m`,
          heightMin: data.types.min_height == 0 ? '指定なし' : `${data.types.min_height}m`,
        }
        setHeightInfos(content)
      } else {
        const content = {
          heightInfo: `第${data.types.koudo_chiku}種高度地区`,
          heightMax: data.types.max_height == 0 ? '指定なし' : `${data.types.max_height}m`,
          heightMin: data.types.min_height == 0 ? '指定なし' : `${data.types.min_height}m`,
        }
        setHeightInfos(content)
      }
    }
    function getHikageInfo(data) {
      if (data.types.hikage == 'I') {
        const content = {
          shade5m: '指定なし',
          shade10m: '指定なし',
          shadeHeight: '指定なし',
        }
        setShadeInfos(content)
      } else {
        const content = {
          shade5m: data.types.shade_5 == 0 ? '指定なし' : `${data.types.shade_5}時間`,
          shade10m: data.types.shade_10 == 0 ? '指定なし' : `${data.types.shade_10}時間`,
          shadeHeight: data.types.shade_height == 0 ? '指定なし' : `${data.types.shade_height}m`,
        }
        setShadeInfos(content)
      }
    }
    function getDistrictInfo(data) {
      const content = {
        planName: data.types.keikaku_meisho,
      }
      setDistrictInfos(content)
    }
    function convertFullwidthToHalfwidth(str) {
      return str.replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0)
      })
    }
    function displayChibanMarker(data, center) {
      displayLayerMarker(
        20,
        [40, 40],
        '11px',
        (d) => {
          const chomeMatch = d.types['丁目名']?.match(/[\uFF10-\uFF19]+/)
          const chomeNumber = chomeMatch ? `${convertFullwidthToHalfwidth(chomeMatch[0])}-` : ''
          return `${chomeNumber}${d.types['地番']}`
        },
        chibanMarkers.current,
        data,
        center,
        '/info_label2.png'
      )
    }
    async function isTokyoArea() {
      const center = mapRef.current.getCenter()
      const latlng = new google.maps.LatLng(center.lat(), center.lng())
      const geocoder = new google.maps.Geocoder()

      try {
        const results = await new Promise((resolve, reject) => {
          geocoder.geocode({ location: latlng, language: 'ja' }, (results, status) => {
            if (status === 'OK') {
              resolve(results)
            } else {
              reject(new Error('Geocoder failed: ' + status))
            }
          })
        })

        const addressComponents = results[0]?.address_components
        const prefecture = addressComponents?.find((c) =>
          c.types.includes('administrative_area_level_1')
        )?.long_name

        if (prefecture === '東京都') {
          return '東京都内'
        } else {
          return '都外'
        }
      } catch (error) {
        console.error(error.message)
        return 'エラーが発生しました'
      }
    }
    async function renderPolygons() {
      let loadingRoot
      let loadingRoot1

      clearCityPlanItems()
      if (polygons_api_base_url == '' || mapRef.current.getZoom() < 17) {
        clearCityPlanItems()
        setShowInfoTable(false)
        return
      }

      !layerButtonStates.current.showPolygonsClicked && clearCityPlanItems()

      infoMarkers.current.forEach((infoMarker) => {
        infoMarker.setMap(null)
      })
      chibanMarkers.current.forEach((chibanMarker) => {
        chibanMarker.setMap(null)
      })
      selectedInfoWindows.current.forEach((infoWindow) => {
        infoWindow.close()
      })
      selectedInfoWindows.current = []
      centerCursors.current.forEach((centerCursor) => {
        centerCursor.setMap(null)
      })
      centerCursor()
      setUsageInfos({
        usageArea: '取得中',
        buildingCoverageRatio: '取得中',
        floorAreaRatio: '取得中',
      })
      setAntifireInfo('取得中'),
      setHeightInfos({
        heightInfo: '取得中',
        heightMax: '取得中',
        heightMin: '取得中',
      })
      setShadeInfos({
        shade5m: '取得中',
        shade10m: '取得中',
        shadeHeight: '取得中',
      })
      setDistrictInfos({
        planName: '取得中',
      })
      mapRef.current.setOptions({
        draggable: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        zoomControl: false,
        scaleControl: false,
        panControl: false,
        minZoom: mapRef.current.getZoom(),
        maxZoom: mapRef.current.getZoom(),
      })

      const center = mapRef.current.getCenter()
      let hasUsageInfo = false
      let hasAntifireInfo = false
      let hasHeightInfo = false
      let hasHikageInfo = false
      let hasDistrictInfo = false

      let polygonTypes = []
      if (layerButtonStates.current.showPolygonsClicked) {
        polygonTypes = polygonTypes.concat(cityPlanItems)
      }
      if (layerButtonStates.current.showKouzuMapClicked) {
        polygonTypes = polygonTypes.concat(cadastralItems)
        if (loadingIconFlg.current !== null || loadingIconFlg1.current !== null) {
          removeLoadingIcon(loadingIconFlg.current, 'kouzuMapButtonLoadingIcon')
          removeLoadingIcon(loadingIconFlg1.current, 'kouzuMapButtonLoadingIcon')
        }
        loadingRoot = createLoadingIcon('kouzuMapButton', 'kouzuMapButtonLoadingIcon')
        loadingRoot1 = createLoadingIcon('kouzuMapButton1', 'kouzuMapButtonLoadingIcon')
        loadingIconFlg.current = loadingRoot
        loadingIconFlg1.current = loadingRoot1
      }

      let selectedPolygons = []
      let originalColors = []
      let selectedChibanInfos = []
      try {
        await Promise.all(
          polygonTypes.map(async (area) => {
            const query2 = new URLSearchParams({
              lat: center.lat(),
              lng: center.lng(),
              polygon_type: area,
            })

            try {
              const res = await fetch(`${polygons_api_base_url}?${query2}`)

              if (!res.ok) {
                throw new Error(`HTTP error! ${res.status} ${res.statusText}`)
              }

              const json = await res.json()

              if (Object.keys(json).indexOf('polygons') === -1) {
                return
              }

              if (json.layer === 'cadastral' && json.polygons.length === 0) {
                removeLoadingIcon(loadingRoot, 'kouzuMapButtonLoadingIcon')
                removeLoadingIcon(loadingRoot1, 'kouzuMapButtonLoadingIcon')
              }

              Object.keys(polygonsHash.current[area]).forEach((key) => {
                polygonsHash.current[area][key].setMap()
                delete polygonsHash.current[area][key]
              })

              const area_colors = {
                youto_chiiki: {
                  '1': '#00C58E', // '第一種低層住居専用地域'
                  '2': '#51E7C7', // '第二種低層住居専用地域'
                  '3': '#7ED043', // '第一種中高層住居専用地域'
                  '4': '#BCE69A', // '第二種中高層住居専用地域'
                  '5': '#FFE395', // '第一種住居地域'
                  '6': '#FFA4A7', // '第二種住居地域'
                  '7': '#FFB92F', // '準住居地域'
                  '8': '#FF8EC3', // '近隣商業地域'
                  '9': '#FF5C5F', // '商業地域'
                  '10': '#9359F5', // '準工業地域'
                  '11': '#8CBBE1', // '工業地域'
                  '12': '#7FA1D5', // '工業専用地域'
                  '0': '#000000', // '指定なし
                },
                bouka_chiiki: {
                  '10': '#000000',
                  '20': '#888888',
                  '0': '#000000',
                },
              }
              json.polygons.forEach((data) => {
                let points = data.lines.map((point) => {
                  return new google.maps.LatLng(Number(point.lat), Number(point.lng))
                })
                let framePoints = points
                const innerPoints = data.innerlines.map((path) => {
                  return path.map((point) => {
                    return new google.maps.LatLng(Number(point.lat), Number(point.lng))
                  })
                })
                if (innerPoints.length > 0) {
                  innerPoints.unshift(points)
                  points = innerPoints
                }

                let option = {
                  paths: points,
                  strokeColor: '#000000',
                  strokeOpacity: 0,
                  strokeWeight: 0,
                  fillColor: '#000000',
                  fillOpacity: 0,
                }

                if (area == 'youto_chiiki' || area == 'bouka_chiiki') {
                  option.strokeColor = area_colors[area][`${data.types[area]}`]
                  option.fillColor = area_colors[area][`${data.types[area]}`]

                  if (data.types[area] === 0) {
                    option.fillOpacity = 0
                    option.strokeOpacity = 0
                  } else {
                    option.strokeOpacity = area == 'youto_chiiki' ? 0.1 : 0
                    option.strokeWeight = area == 'youto_chiiki' ? 0.5 : 0
                    option.fillOpacity = area == 'youto_chiiki' ? 0.3 : 0.1
                  }
                } else if (area == 'koudo_chiku') {
                  option.strokeColor = '#0066F9'
                  option.strokeOpacity = data.types.koudo_chiku == 0 ? 0 : 0.5
                  option.strokeWeight = 1
                } else if (area == 'hikage_kisei') {
                  option.strokeOpacity = 0.15
                  option.strokeWeight = 2
                } else if (area == 'kouzu_map') {
                  option.strokeColor = '#125690'
                  option.strokeOpacity = 0.3
                  option.strokeWeight = 1
                  option.fillColor = '#e1f0fc'
                  option.fillOpacity = 0.1
                }
                const polygon = new google.maps.Polygon(option)

                let minLatLng = mapRef.current.getBounds().getSouthWest()
                let maxLatLng = mapRef.current.getBounds().getNorthEast()
                let pad_lat_range = (Number(maxLatLng.lat()) - Number(minLatLng.lat())) * 0.1
                let pad_lng_range = (Number(maxLatLng.lng()) - Number(minLatLng.lng())) * 0.1

                const processPolygons = (displayMarkerFunc) => {
                  const targetPoints = []
                  framePoints.forEach((point) => {
                    if (
                      Number(point.lat()) > Number(minLatLng.lat()) &&
                      Number(point.lat()) < Number(maxLatLng.lat()) &&
                      Number(point.lng()) > Number(minLatLng.lng()) &&
                      Number(point.lng()) < Number(maxLatLng.lng())
                    ) {
                      targetPoints.push(point)
                    }
                  })
                  // 地図枠内にパスがあるポリゴンのみ表示
                  if (targetPoints.length > 0) {
                    let bounds = new google.maps.LatLngBounds()
                    targetPoints.forEach((point) => {
                      bounds.extend(point)
                    })
                    let center = bounds.getCenter()
                    // ポリゴンの中心が地図の外側に出ている場合、地図の内側に収まるように調整
                    if (center.lat() < minLatLng.lat()) {
                      center = new google.maps.LatLng(minLatLng.lat() + pad_lat_range, center.lng())
                    }
                    if (center.lat() > maxLatLng.lat()) {
                      center = new google.maps.LatLng(
                        maxLatLng.lat() - pad_lat_range * 2,
                        center.lng()
                      )
                    }
                    if (center.lng() < minLatLng.lng()) {
                      center = new google.maps.LatLng(center.lat(), minLatLng.lng() + pad_lng_range)
                    }
                    if (center.lng() > maxLatLng.lng()) {
                      center = new google.maps.LatLng(
                        center.lat(),
                        maxLatLng.lng() - pad_lng_range * 2
                      )
                    }
                    // ポリゴンの中心点がポリゴンの外側にある場合（L状のようなポリゴンとか）、ポリゴンの内側に収まるように調整
                    if (google.maps.geometry.poly.containsLocation(center, polygon)) {
                      displayMarkerFunc(data, center)
                    } else {
                      pad_lat_range = pad_lat_range / 2
                      pad_lng_range = pad_lng_range / 2
                      let minLat = minLatLng.lat() + pad_lat_range
                      let minLng = minLatLng.lng() + pad_lng_range
                      lng = Number(minLng)
                      lat = Number(minLat)
                      while (!google.maps.geometry.poly.containsLocation(center, polygon)) {
                        if (center.lng() > mapRef.current.getBounds().getNorthEast().lng()) {
                          lat = lat + Number(pad_lat_range)
                          lng =
                            mapRef.current.getBounds().getSouthWest().lng() + Number(pad_lng_range)
                        }
                        center = new google.maps.LatLng(Number(lat), Number(lng))
                        if (
                          center.lat() > mapRef.current.getBounds().getNorthEast().lat() &&
                          center.lng() > mapRef.current.getBounds().getNorthEast().lng()
                        ) {
                          break
                        }
                        lng = Number(lng) + Number(pad_lng_range)
                      }
                      displayMarkerFunc(data, center)
                    }
                  }
                }

                switch (area) {
                  case 'youto_chiiki':
                    // 0番用途地域でない場合、ラベルを貼る
                    if (data.types.youto_chiiki != 0) {
                      processPolygons(displayInfoMarker)
                    }
                    if (
                      google.maps.geometry.poly.containsLocation(
                        mapRef.current.getCenter(),
                        polygon
                      )
                    ) {
                      getUsageInfo(data)
                      hasUsageInfo = true
                    }
                    break

                  case 'bouka_chiiki':
                    if (
                      google.maps.geometry.poly.containsLocation(
                        mapRef.current.getCenter(),
                        polygon
                      )
                    ) {
                      getAntifireInfo(data)
                      hasAntifireInfo = true
                    }
                    break

                  case 'koudo_chiku':
                    if (
                      google.maps.geometry.poly.containsLocation(
                        mapRef.current.getCenter(),
                        polygon
                      )
                    ) {
                      getHeightInfo(data)
                      hasHeightInfo = true
                    }
                    break

                  case 'hikage_kisei':
                    if (
                      google.maps.geometry.poly.containsLocation(
                        mapRef.current.getCenter(),
                        polygon
                      )
                    ) {
                      getHikageInfo(data)
                      hasHikageInfo = true
                    }
                    break

                  case 'chiku_keikaku':
                    if (
                        google.maps.geometry.poly.containsLocation(
                            mapRef.current.getCenter(),
                            polygon
                        )
                    ) {
                      getDistrictInfo(data)
                      hasDistrictInfo = true
                    }
                    break

                  case 'kouzu_map':
                    if (mapRef.current.getZoom() == 20) {
                      processPolygons(displayChibanMarker)
                      google.maps.event.addListener(polygon, 'click', function (mouseEvent) {
                        let index = selectedPolygons.indexOf(this)
                        if (index !== -1) {
                          this.setOptions({ fillColor: originalColors[index] })
                          selectedPolygons.splice(index, 1)
                          originalColors.splice(index, 1)
                          selectedChibanInfos.splice(index, 1)
                          if (selectedInfoWindows.current[index]) {
                            selectedInfoWindows.current[index].close()
                            selectedInfoWindows.current.splice(index, 1)
                          }
                        } else {
                          originalColors.push(this.get('fillColor'))
                          this.setOptions({ fillColor: '#FF0000' })
                          selectedPolygons.push(this)
                          const chomeMatch = data.types['丁目名']?.match(/[\uFF10-\uFF19]+/)
                          const chomeNumber = chomeMatch
                            ? `${convertFullwidthToHalfwidth(chomeMatch[0])}-`
                            : ''
                          const chibanInfo = `${chomeNumber}${data.types['地番']}`
                          selectedChibanInfos.push(chibanInfo)
                          const infoWindow = new google.maps.InfoWindow({
                            content: `地番：${chibanInfo}`,
                            position: mouseEvent.latLng,
                          })
                          infoWindow.open(mapRef.current)
                          selectedInfoWindows.current.push(infoWindow)
                        }
                      })
                    } else {
                      chibanMarkers.current.forEach((chibanMarker) => {
                        chibanMarker.setMap(null)
                      })
                    }
                    break

                  default:
                    console.log('Unknown area: ', area)
                }

                polygon.setMap(mapRef.current)
                polygonsHash.current[area][data.id] = polygon
              })
            } catch (err) {
              console.error(`Failed to fetch polygon for area ${area}: ${err}`)
            } finally {
              removeLoadingIcon(loadingRoot, 'kouzuMapButtonLoadingIcon')
              removeLoadingIcon(loadingRoot1, 'kouzuMapButtonLoadingIcon')
              if (area == 'youto_chiiki' && !hasUsageInfo) {
                setUsageInfos({
                  usageArea: 'N/A',
                  buildingCoverageRatio: 'N/A',
                  floorAreaRatio: 'N/A',
                })
              } else if (area == 'bouka_chiiki' && !hasAntifireInfo) {
                isTokyoArea().then((result) => {
                  if (result === '東京都内') {
                    setAntifireInfo('-')
                  } else {
                    setAntifireInfo('N/A')
                  }
                })
              } else if (area == 'koudo_chiku' && !hasHeightInfo) {
                isTokyoArea().then((result) => {
                  if (result === '東京都内') {
                    setHeightInfos({
                      heightInfo: '-',
                      heightMax: '-',
                      heightMin: '-',
                    })
                  } else {
                    setHeightInfos({
                      heightInfo: 'N/A',
                      heightMax: 'N/A',
                      heightMin: 'N/A',
                    })
                  }
                })
              } else if (area == 'hikage_kisei' && !hasHikageInfo) {
                setShadeInfos({
                  shade5m: 'N/A',
                  shade10m: 'N/A',
                  shadeHeight: 'N/A',
                })
              } else if (area == 'chiku_keikaku' && !hasDistrictInfo) {
                setDistrictInfos({
                  planName: 'N/A',
                })
              }
              mapRef.current.setOptions({
                draggable: true,
                scrollwheel: true,
                disableDoubleClickZoom: false,
                zoomControl: true,
                scaleControl: true,
                panControl: true,
                minZoom: 0,
                maxZoom: 20,
              })
            }
          })
        )
      } catch (error) {
        console.error(`Failed to complete all polygon fetch requests: ${error}`)
      }
    }
    function clearCadastralItems() {
      clearItems(cadastralItems)
      chibanMarkers.current.forEach((chibanMarker) => {
        chibanMarker.setMap(null)
      })
    }
    function handleLayerButtonStatesChange(newLayerButtonStates) {
      const oldLayerButtonStates = { ...layerButtonStates.current }
      layerButtonStates.current = newLayerButtonStates

      if (newLayerButtonStates.showPolygonsClicked !== oldLayerButtonStates.showPolygonsClicked) {
        if (newLayerButtonStates.showPolygonsClicked) {
          setShowInfoTable(true)
          renderPolygons()
        } else {
          clearCityPlanItems()
        }
      }
      if (newLayerButtonStates.showKouzuMapClicked !== oldLayerButtonStates.showKouzuMapClicked) {
        if (newLayerButtonStates.showKouzuMapClicked) {
          renderPolygons()
        } else {
          clearCadastralItems()
        }
      }
    }

    const usageButtonFunction = () => {
      const newLayerButtonStates = {
        ...layerButtonStates.current,
        showPolygonsClicked: !layerButtonStates.current.showPolygonsClicked,
      }
      handleLayerButtonStatesChange(newLayerButtonStates)

      if (!newLayerButtonStates.showPolygonsClicked) {
        document.getElementById('youto_chiiki').style.color = '#0A689D'
        document.getElementById('usageButton').style.color = 'black'
        setShowInfoTable(false)
        mapRef.current.setOptions({
          draggable: true,
          scrollwheel: true,
          disableDoubleClickZoom: false,
          zoomControl: true,
          scaleControl: true,
          panControl: true,
          minZoom: 0,
          maxZoom: 20,
        })
      } else {
        document.getElementById('youto_chiiki').style.color = 'red'
        document.getElementById('usageButton').style.color = 'red'
      }
    }

    const kouzuMapButtonFunction = () => {
      let newLayerButtonStates = {
        ...layerButtonStates.current,
        showKouzuMapClicked: !layerButtonStates.current.showKouzuMapClicked,
      }
      handleLayerButtonStatesChange(newLayerButtonStates)

      // kouzuMapButton.style.display = 'block'
      if (newLayerButtonStates.showKouzuMapClicked) {
        document.getElementById('kouzuMapButton').style.color = 'red'
        document.getElementById('kouzuMapButton1').style.color = 'red'
      } else {
        document.getElementById('kouzuMapButton').style.color = '#0A689D'
        document.getElementById('kouzuMapButton1').style.color = 'black'
      }
    }

    const containerStyle = React.useMemo(() => {
      return (
        style ?? {
          width: '100%',
          height: '600px',
        }
      )
    }, [style])

    React.useEffect(() => {
      const element = document.getElementById('showInfoTable')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, [showInfoTable])

    React.useEffect(() => {
      const interval = setInterval(() => {
        const pinElement = document.querySelector<HTMLButtonElement>("button[title='描画をやめる']")
        if (pinElement) {
          pinElement.ariaLabel = 'ピンで示す'
          pinElement.title = 'ピンで示す'
        }

        const polygonElement =
          document.querySelector<HTMLButtonElement>("button[title='図形を描画']")
        if (polygonElement) {
          polygonElement.ariaLabel = '敷地を描画'
          polygonElement.title = '敷地を描画'
        }
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }, [])
    const [siteArea, setSiteArea] = React.useState('')

    React.useEffect(() => {
      setSiteArea(existdAreaValue)
    }, [existdAreaValue])

    React.useEffect(() => {
      const isTargetMode = mode === 'volume'

      if (!isTargetMode) return
      const hasAreaText = areaTextFromResponse !== '' || siteArea !== ''
      const hasAreaShape = areaShape !== null

      if (hasAreaText && hasAreaShape) {
        const areaValue = areaTextFromResponse || siteArea
        if (showAreaTextRef.current) {
          showAreaTextRef.current(areaShape, areaValue)
        }
      }
    }, [
      areaTextFromResponse,
      requestUUID,
      siteArea,
      mode === 'volume_detail' ? areaShape : undefined,
    ])
    const usageSvgAttributes = {
      class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
      focusable: 'false',
      'aria-hidden': 'true',
      viewBox: '0 0 25 28',
    }

    function generateSvg(path) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" {...usageSvgAttributes}>
          <path d={path} />
        </svg>
      )
    }

    const usageSvg = generateSvg(
      'M17 11V3H7v4H3v14h8v-4h2v4h8V11h-4zM7 19H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm4 4H9v-2h2v2zm0-4H9V9h2v2zm0-4H9V5h2v2zm4 8h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2z'
    )
    const kouzuMapSvg = generateSvg(
      'M3 21h2v-2H3v2zm4 0h2v-2H7v2zM5 7H3v2h2V7zM3 17h2v-2H3v2zM9 3H7v2h2V3zM5 3H3v2h2V3zm12 0h-2v2h2V3zm2 6h2V7h-2v2zm0-6v2h2V3h-2zm-4 18h2v-2h-2v2zM13 3h-2v8H3v2h8v8h2v-8h8v-2h-8V3zm6 18h2v-2h-2v2zm0-4h2v-2h-2v2z'
    )

    const centerControlInitialization = (map: google.maps.Map) => {

      const centerControlDiv = document.createElement('div');
      centerControlDiv.style.margin = '40px'

      const up = createCenterButton('上')
      up.addEventListener('click',() => upClick)
      up.addEventListener('mousedown',() => startInterval(upClick))
      up.addEventListener('mouseup',stopInterval)
      up.addEventListener('mouseleave',stopInterval)
      up.addEventListener('pointerdown', () => startInterval(upClick))
      up.addEventListener('pointerup', stopInterval)
      up.addEventListener('pointerleave', stopInterval)
      const down = createCenterButton('下')
      down.addEventListener('click',() => downClick)
      down.addEventListener('mousedown',() => startInterval(downClick))
      down.addEventListener('mouseup',stopInterval)
      down.addEventListener('mouseleave',stopInterval)
      down.addEventListener('pointerdown', () => startInterval(downClick))
      down.addEventListener('pointerup', stopInterval)
      down.addEventListener('pointerleave', stopInterval)
      const left = createCenterButton('左')
      left.addEventListener('click',() => leftClick)
      left.addEventListener('mousedown',() => startInterval(leftClick))
      left.addEventListener('mouseup',stopInterval)
      left.addEventListener('mouseleave',stopInterval)
      left.addEventListener('pointerdown', () => startInterval(leftClick))
      left.addEventListener('pointerup', stopInterval)
      left.addEventListener('pointerleave', stopInterval)
      const right = createCenterButton('右')
      right.addEventListener('click',() => rightClick)
      right.addEventListener('mousedown',() => startInterval(rightClick))
      right.addEventListener('mouseup',stopInterval)
      right.addEventListener('mouseleave',stopInterval)
      right.addEventListener('pointerdown', () => startInterval(rightClick));
      right.addEventListener('pointerup', stopInterval)
      right.addEventListener('pointerleave', stopInterval)
      const enlarge = createCenterButton('拡大')
      enlarge.addEventListener('click',() =>enlargeClick(1))
      enlarge.addEventListener('mousedown',() => startInterval(() =>enlargeClick(1)))
      enlarge.addEventListener('mouseup',stopInterval)
      enlarge.addEventListener('mouseleave',stopInterval)
      enlarge.addEventListener('pointerdown', () => startInterval(() =>enlargeClick(1)));
      enlarge.addEventListener('pointerup', stopInterval)
      enlarge.addEventListener('pointerleave', stopInterval)
      const shrink = createCenterButton('縮小')
      shrink.addEventListener('click',() =>enlargeClick(0))
      shrink.addEventListener('mousedown',() => startInterval(() =>enlargeClick(0)))
      shrink.addEventListener('mouseup',stopInterval)
      shrink.addEventListener('mouseleave',stopInterval)
      shrink.addEventListener('pointerdown', () => startInterval(() =>enlargeClick(0)))
      shrink.addEventListener('pointerup', stopInterval)
      shrink.addEventListener('pointerleave', stopInterval)
      const rotateL = createCenterButton('左回り')
      rotateL.addEventListener('click',() =>rotateClick(0))
      rotateL.addEventListener('mousedown',() => startInterval(() =>rotateClick(0)))
      rotateL.addEventListener('mouseup',stopInterval)
      rotateL.addEventListener('mouseleave',stopInterval)
      rotateL.addEventListener('pointerdown', () => startInterval(() =>rotateClick(0)))
      rotateL.addEventListener('pointerup', stopInterval)
      rotateL.addEventListener('pointerleave', stopInterval)
      const rotateR = createCenterButton('右回り')
      rotateR.addEventListener('click',() => rotateClick(1))
      rotateR.addEventListener('mousedown',() => startInterval(() =>rotateClick(1)))
      rotateR.addEventListener('mouseup',stopInterval)
      rotateR.addEventListener('mouseleave',stopInterval)
      rotateR.addEventListener('pointerdown', () => startInterval(() =>rotateClick(1)))
      rotateR.addEventListener('pointerup', stopInterval)
      rotateR.addEventListener('pointerleave', stopInterval)

      centerControlDiv.appendChild(up);
      centerControlDiv.appendChild(down);
      centerControlDiv.appendChild(left);
      centerControlDiv.appendChild(right);
      centerControlDiv.appendChild(enlarge);
      centerControlDiv.appendChild(shrink);
      centerControlDiv.appendChild(rotateL);
      centerControlDiv.appendChild(rotateR);

      map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);
    }

    //create button
    const createCenterButton = (textContent: string) => {
      const controlButton = document.createElement('button');

      controlButton.style.background = 'none padding-box rgb(255, 255, 255)';
      controlButton.style.width = '60px';
      controlButton.style.border = '0';
      controlButton.style.boxShadow = 'rgba(0, 0, 0, 0.6) 0px 1px 4px -1px';
      controlButton.style.color = 'rgb(25,25,25)';
      controlButton.style.cursor = 'pointer';
      controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
      controlButton.style.fontSize = '16px';
      controlButton.style.padding = '6px';
      controlButton.style.textAlign = 'center';
      controlButton.addEventListener("mouseenter", function(event) {
        controlButton.style.background = 'none padding-box rgb(235, 235, 235)';
      });
      controlButton.addEventListener("mouseleave", function(event) {
        controlButton.style.background = 'none padding-box rgb(255, 255, 255)';
      });

      controlButton.textContent = textContent;
      controlButton.type = 'button';

      return controlButton;
    }

    const upClick = () => {
      setResponseArea('　')
      const newPath = movePolygon.getPath().getArray().map(function(latLng) {
        return {
          lat: latLng.lat() + movingDistance,
          lng: latLng.lng()
        };
      });
      movePolygon.setPath(newPath);
      showAreaMarkers(movePolygon)
      const shape = []
      movePolygon.getPath().forEach((latlng) => {
        shape.push({ lat: latlng.lat(), lng: latlng.lng() })
      })
      onChangeShape(JSON.stringify(shape))
    }

    const downClick = () => {
      setResponseArea('　')
      const newPath = movePolygon.getPath().getArray().map(function(latLng) {
        return {
          lat: latLng.lat() - movingDistance,
          lng: latLng.lng()
        };
      });
      movePolygon.setPath(newPath);
      showAreaMarkers(movePolygon)
      const shape = []
      movePolygon.getPath().forEach((latlng) => {
        shape.push({ lat: latlng.lat(), lng: latlng.lng() })
      })
      onChangeShape(JSON.stringify(shape))
    }

    const leftClick = () => {
      setResponseArea('　')
      const newPath = movePolygon.getPath().getArray().map(function(latLng) {
        return {
          lat: latLng.lat(),
          lng: latLng.lng() - movingDistance
        };
      });
      movePolygon.setPath(newPath);
      showAreaMarkers(movePolygon)
      const shape = []
      movePolygon.getPath().forEach((latlng) => {
        shape.push({ lat: latlng.lat(), lng: latlng.lng() })
      })
      onChangeShape(JSON.stringify(shape))
    }

    const rightClick = () => {
      setResponseArea('　')
      const newPath = movePolygon.getPath().getArray().map(function(latLng) {
        return {
          lat: latLng.lat(),
          lng: latLng.lng() + movingDistance
        };
      });
      movePolygon.setPath(newPath);
      showAreaMarkers(movePolygon)
      const shape = []
      movePolygon.getPath().forEach((latlng) => {
        shape.push({ lat: latlng.lat(), lng: latlng.lng() })
      })
      onChangeShape(JSON.stringify(shape))
    }

    const rotatePoint = (point: google.maps.Point, origin: google.maps.Point, angle: number, rotate: number) => {
      if (rotate === 1) {
        angle = -angle
      }
      const angleRad = angle;
      return {
        x: Math.cos(angleRad) * (point.x - origin.x) - Math.sin(angleRad) * (point.y - origin.y) + origin.x,
        y: Math.sin(angleRad) * (point.x - origin.x) + Math.cos(angleRad) * (point.y - origin.y) + origin.y
      };
    }

    const rotatePolygon = (polygon: google.maps.Polygon, angle: number, rotate: number) => {
      const map = polygon.getMap();
      const prj = map.getProjection();
      const origin = prj.fromLatLngToPoint(polygon.getPath().getAt(0));

      const coords = polygon.getPath().getArray().map(function(latLng){
        const point = prj.fromLatLngToPoint(latLng);
        const rotatedLatLng =  prj.fromPointToLatLng(rotatePoint(point, origin, angle, rotate));
        return {lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng()};
      });
      polygon.setPath(coords);
    }

    const rotateClick = (rotate: number) => {
      setResponseArea('　')
      rotatePolygon(movePolygon, rotationAngle, rotate)
      showAreaMarkers(movePolygon)
      const shape = []
      movePolygon.getPath().forEach((latlng) => {
        shape.push({ lat: latlng.lat(), lng: latlng.lng() })
      })
      onChangeShape(JSON.stringify(shape))
      getDistance()
    }

    //around: 0:right 1:left
    // const rotatePoint = (point: google.maps.LatLng, center: google.maps.LatLng, angle: any, rotate: number) => {
    //   if (rotate === 1) {
    //     angle = -angle
    //   }
    //
    //   const s = Math.sin(angle);
    //   const c = Math.cos(angle);
    //
    //   const x = point.lng() - center.lng();
    //   const y = point.lat() - center.lat();
    //   let newX,newY
    //
    //   newX = x * c - y * s;
    //   newY = x * s + y * c;
    //
    //   newX += center.lng();
    //   newY += center.lat();
    //
    //   return new google.maps.LatLng(newY, newX);
    // }

    //around: 0:right 1:left
    const enlargeClick = (enlarge: number) => {
      setResponseArea('　')
      const bounds = new google.maps.LatLngBounds();
      movePolygon.getPath().forEach(function (point) {
        bounds.extend(point);
      });
      const center = bounds.getCenter();

      const newPath = movePolygon.getPath().getArray().map(function(point) {
        const scaledPoint = scalePoint(point, center, scaleRatio, enlarge);
        return { lat: scaledPoint.lat(), lng: scaledPoint.lng() };
      });

      movePolygon.setPaths(newPath);
      showAreaMarkers(movePolygon)
      const shape = []
      movePolygon.getPath().forEach((latlng) => {
        shape.push({ lat: latlng.lat(), lng: latlng.lng() })
      })
      onChangeShape(JSON.stringify(shape))
      getDistance()
    }

    const getDistance = (polygon = movePolygon) => {
      const pathArray = polygon.getPath().getArray();
      const lengths: string[] = []
      let next_i = 0
      for (let i = 0; i < pathArray.length; i++) {
        if (i == pathArray.length - 1) { next_i = 0 } else { next_i = i + 1 }
        const distance = google.maps.geometry.spherical.computeDistanceBetween(pathArray[i], pathArray[next_i])
        lengths[i] = `${Math.round(distance * 100) / 100}m`
      }
      setWidthLength(lengths)
    }

    const scalePoint = (point: google.maps.LatLng, center: google.maps.LatLng, scaleRatio: number, enlarge: number) => {
      let scale;
      if (enlarge === 1) {
        scale = 1 + scaleRatio
      } else {
        scale = 1 - scaleRatio
      }

      const deltaX = point.lng() - center.lng();
      const deltaY = point.lat() - center.lat();
      const newDeltaX = deltaX * scale;
      const newDeltaY = deltaY * scale;
      return new google.maps.LatLng(center.lat() + newDeltaY, center.lng() + newDeltaX);
    }

    window.addEventListener("keydown", function(event) {
      if (event.ctrlKey) {
        movingDistance = movingDistanceCtrled;

        rotationAngle = rotationAngleCtrled;

        scaleRatio = scaleRatioCtrled;
      }
    });

    window.addEventListener("keyup", function(event) {
      if (event.key === "Control") {
        movingDistance = movingDistanceUnCtrl;

        rotationAngle = rotationAngleUnCtrl;

        scaleRatio = scaleRatioUnCtrl;
      }
    });
    function startInterval(callback) {
      if (intervalId === null) {
        intervalId = setInterval(callback, 100);
      }
    }

    function stopInterval() {
      if (intervalId !== null) {
        clearInterval(intervalId);
        intervalId = null;
      }
    }

    return (
      <>
        <GoogleMap mapContainerStyle={containerStyle} options={mapOptions} onLoad={onLoad} />
        <span
          className={`mt-4 inline-block leading-none text-[10px] w-full ${
            areaShape || areaShape === '' ? 'border-[#d4c5c5]' : 'border-primary'
          }`}
        >
          <div className="md:flex items-center justify-between gap-4 mt-2 flex-wrap relative">
            <Button
              outline
              size="small"
              type="button"
              onClick={openModal}
              className={`${current_user.role !== 'system_admin' ? 'hidden' : 'block'}`}
            >
              <DesignServicesIcon fontSize="small" className="text-primary-font" />
              ファイルから敷地を取り込み
            </Button>
            <VolumeCheckChooseModal
                isOpen={isOpen}
                closeModal={() => closeModal()}
                volume_check_detail={volume_check_detail}
                ref_volume_check_parameters={ref_volume_check_parameters}
                plan_number={plan_number}
                authenticity_token={authenticity_token}
            ></VolumeCheckChooseModal>
            <div className="flex items-center py-[5px] rounded-sm border border-primary-font area-btn">
              {checkAreaButton}
            </div>
          </div>
          <div className="md:flex items-center mt-2 flex-wrap">
            <Button
              id="kouzuMapButton"
              outline
              size="small"
              type="button"
              className={`${current_user.role !== 'system_admin' ? 'hidden' : 'block'}`}
              onClick={openKouzuModal}
            >
              {kouzuMapSvg}公図情報から敷地を選択
            </Button>
            <KouzuMapModal
                isOpen={isOpenKouzu}
                closeModal={() => closeKouzuModal()}
                polygons_api_base_url={polygons_api_base_url}
                lat={lat}
                lng={lng}
                zoom={zoom}
                mode={mode}
                property_id={property_id}
                csrfToken={csrfToken}
                authenticity_token={authenticity_token}
                volume_check_detail={volume_check_detail}
                ref_volume_check_parameters={ref_volume_check_parameters}
                plan_number={plan_number}
            ></KouzuMapModal>
          </div>
          <div className="border-primary-font border-b mt-4 mb-4"></div>
          <div className="md:flex items-center gap-4 mt-2 flex-wrap">
            <Button
              id="youto_chiiki"
              outline
              size="small"
              type="button"
              onClick={usageButtonFunction}
            >
              {usageSvg}用途地域情報確認
            </Button>
            <Button
              outline
              size="small"
              type="button"
              className={`${current_user.role !== 'system_admin' ? 'hidden' : 'block'}`}
            >
              <StreetviewIcon fontSize="small" />
              地区計画情報確認
            </Button>
            <Button
              outline
              size="small"
              type="button"
              className={`${current_user.role !== 'system_admin' ? 'hidden' : 'block'}`}
            >
              <RouteIcon fontSize="small" />
              計画道路情報確認
            </Button>
            <Button
              outline
              size="small"
              type="button"
              className={`${current_user.role !== 'system_admin' ? 'hidden' : 'block'}`}
            >
              <ParkIcon fontSize="small" />
              風致地区情報確認
            </Button>
          </div>
          <div className="border-primary-font border-b mt-4 mb-4"></div>
        </span>
        {showInfoTable && (
          <>
            <Table className="border-t border-b border-[#3885B0]" tag="div">
              <div id="showInfoTable">
                <Row label={<Th left={true}>用途地域</Th>}>
                  <Td>{usageInfos.usageArea == '取得中' ? loading : usageInfos.usageArea}</Td>
                </Row>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        建ぺい率
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {usageInfos.buildingCoverageRatio == '取得中'
                        ? loading
                        : usageInfos.buildingCoverageRatio}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        容積率
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {usageInfos.floorAreaRatio == '取得中' ? loading : usageInfos.floorAreaRatio}
                    </Td>
                  </Row>
                </div>
                <Row label={<Th left={true}>防火地域</Th>}>
                  <Td>{antifireInfo == '取得中' ? loading : antifireInfo}</Td>
                </Row>
                <Row label={<Th left={true}>高度地区</Th>}>
                  <Td>{heightInfos.heightInfo == '取得中' ? loading : heightInfos.heightInfo}</Td>
                </Row>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        最高高度
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMax == '取得中' ? loading : heightInfos.heightMax}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        最低高度
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMin == '取得中' ? loading : heightInfos.heightMin}
                    </Td>
                  </Row>
                </div>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        日影範囲5M超
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMax == '取得中' ? loading : shadeInfos.shade5m}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        日影範囲10M超
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMin == '取得中' ? loading : shadeInfos.shade10m}
                    </Td>
                  </Row>
                </div>
                <Row label={<Th left={true}>日影測定水平面</Th>}>
                  <Td>{heightInfos.heightInfo == '取得中' ? loading : shadeInfos.shadeHeight}</Td>
                </Row>
                <Row label={<Th left={true}>地区計画</Th>}>
                  <Td>{districtInfos.planName == '取得中' ? loading : districtInfos.planName}</Td>
                </Row>
              </div>
            </Table>
            <div className="block text-xs px-4 pt-1 pb-2 whitespace-normal text-primary font-small">
              ※ 情報データが提供されていない地域の場合は、表内に"N/A"と表示されます。
            </div>
          </>
        )}
      </>
    )
  }
)
