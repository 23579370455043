export type OcrFormat = 'rent' | 'purchase' | 'acquisition'
export type OcrResultType = AcquisitionResult | RentResult | PurchaseResult

// 仕入れ
export type Acquisition = {
  result: AcquisitionResult
}

// フォームに使用していない項目は、削除しないがコメントアウトをする。
export type AcquisitionResult = {
  住所: address
  地番: string[]
  物件名: string
  土地: {
    // 地目: string
    地積公簿: string
    地積実測: string
  }
  現況: string
  // 用途: string
  建物: {
    構造: string
    築年月: string
    総戸数: number
    延床面積: string
    専有面積: string
    階数: {
      地上: number
      地下: number
    }
  }
  検査済証: string
  // 間取り: string[]
  最寄り: {
    路線名: string
    駅名: string
    徒歩時間: string
    バス時間: string
    バス下車徒歩: string
  }[]
  道路: {
    方位: string
    幅員: string
    間口: string
    道路タイプ: string
    条項: string
  }[]
  // 家屋番号: string[]
  用途地域: {
    種類: string
    建蔽率: string
    容積率: string
  }[]
  //防火地域: string[]
  // 高度地区: string[]
  //日影規制: string[]
  // 収入: {
  //   金額: number
  //   内訳: string
  // }
  // 支出: {
  //   金額: number
  //   内訳: string
  // }
  販売金額: string
  表面利回り: string
  ネット利回り: string
  // 成約NOI利回り: string
  // 路線価: number
  物件種目: string
  '棟数（区画）': string
  月割り現況賃料: string
  部屋番号: string
}

type address = {
  都道府県: string
  市区町村: string
  町名: string
  '丁目・その他': string
}

export type AddressMember = keyof address

export const exampleFormatAcquisition: Acquisition = {
  result: {
    住所: { 都道府県: '東京都', 市区町村: '港区', 町名: '北青山', '丁目・その他': '2-7-9' },
    地番: ['499-1,499-2,499-3,499-4'],
    物件名: '東京タワー',
    物件種目: '土地 or 土地＋建物（一棟）or 土地＋建物（区分）',
    土地: { 地積公簿: '100.00m2', 地積実測: '100.01m2' },
    現況: '更地 or 建物有（全空）or 建物有（稼働中） or 解体中 or 建築中',
    道路: [
      { 方位: '北', 幅員: '5.77m', 間口: '10.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
      { 方位: '南', 幅員: '1.77m', 間口: '3.0m', 道路タイプ: '私道', 条項: '通路' },
      { 方位: '北西', 幅員: '34.77m', 間口: '3.0m', 道路タイプ: '公道', 条項: '42条1項2号' },
      { 方位: '南東', 幅員: '4.77m', 間口: '13.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
    ],
    建物: {
      構造: 'rc or wrc or src or s_light or s_heavy or w or al or cft or cb or others',
      階数: { 地上: 5, 地下: 1 },
      築年月: '1990-06-01',
      総戸数: 10,
      延床面積: '1000.00m2',
      専有面積: '1000.00m2',
    },
    検査済証: '有',
    最寄り: [
      {
        路線名: '京王線',
        駅名: '京王線新宿',
        徒歩時間: '5分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '京王線',
        駅名: '京王線千歳烏山',
        徒歩時間: '',
        バス時間: '10分',
        バス下車徒歩: '5分',
      },
      {
        路線名: '南武線',
        駅名: '府中本町',
        徒歩時間: '20分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '小田急小田原線',
        駅名: '町田',
        徒歩時間: '25分',
        バス時間: '4分',
        バス下車徒歩: '3分',
      },
    ],
    用途地域: [
      { 種類: '商業地域', 建蔽率: '80', 容積率: '500' },
      { 種類: '第二種住居地域', 建蔽率: '10', 容積率: '300' },
      { 種類: '田園地域', 建蔽率: '90', 容積率: '400' },
      { 種類: '工業専用地域', 建蔽率: '20', 容積率: '100' },
    ],
    販売金額: '5000万円',
    表面利回り: '5%',
    ネット利回り: '4%',
    '棟数（区画）': '5',
    月割り現況賃料: '1200000円',
    部屋番号: '205号室',
  },
}

export type Rent = {
  result: RentResult
}

export type RentResult = {
  募集状態: string
  住所: address
  最寄り: {
    路線名: string
    駅名: string
    徒歩時間: string
    バス時間: string
    バス下車徒歩: string
  }[]
  道路: {
    方位: string
    幅員: string
    間口: string
    道路タイプ: string
    条項: string
  }[]
  掲載日: string
  成約日: string
  物件名: string
  建物: {
    構造: string
    竣工年月: string
    延床面積: string
    専有面積: string
    階数: {
      地上: number
      地下: number
    }
  }
  賃料: { 号室: string; 金額: string; 管理費: string; 敷金: string; 礼金: string }
  向き: string
  間取り: string
  エレベータ: string
  オートロック: string
  バルコニー: string
  ネット無料: string
  家具家電: string
  その他: { 特色: string[]; 注記: string[] }
  施工会社: string
  所在階: { 階層: string; 階数: number }
  物件タイプ: string
  物件種目: string
}

export const exampleFormatRent: Rent = {
  result: {
    募集状態: '募集中 or 成約',
    住所: { 都道府県: '東京', 市区町村: '港区', 町名: '北青山', '丁目・その他': '2-7-9' },
    物件名: '東京タワー',
    間取り: '3LDK',
    最寄り: [
      {
        路線名: '京王線',
        駅名: '京王線新宿',
        徒歩時間: '5分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '京王線',
        駅名: '京王線千歳烏山',
        徒歩時間: '',
        バス時間: '10分',
        バス下車徒歩: '5分',
      },
      {
        路線名: '南武線',
        駅名: '府中本町',
        徒歩時間: '20分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '小田急小田原線',
        駅名: '町田',
        徒歩時間: '25分',
        バス時間: '4分',
        バス下車徒歩: '3分',
      },
    ],
    道路: [
      { 方位: '北', 幅員: '5.77m', 間口: '10.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
      { 方位: '南', 幅員: '1.77m', 間口: '3.0m', 道路タイプ: '私道', 条項: '通路' },
      { 方位: '北西', 幅員: '34.77m', 間口: '3.0m', 道路タイプ: '公道', 条項: '42条1項2号' },
      { 方位: '南東', 幅員: '4.77m', 間口: '13.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
    ],
    掲載日: '2023-10-01',
    成約日: '2023-10-02',
    建物: {
      構造: 'rc or wrc or src or s_light or s_heavy or w or al or cft or cb or others',
      階数: { 地上: 5, 地下: 1 },
      竣工年月: '1990-06-01',
      延床面積: '1000.00m2',
      専有面積: '1000.00m2',
    },
    賃料: {
      号室: '102',
      金額: '115000円',
      管理費: '10000円',
      敷金: '10000円',
      礼金: '0円',
    },
    向き: '南西',
    エレベータ: '有り or 無し',
    オートロック: '有り or 無し',
    バルコニー: '有り or 無し',
    ネット無料: '有り or 無し',
    家具家電: '有り or 無し',
    施工会社: '清水建設',
    物件タイプ: 'ビル',
    所在階: { 階層: '地上 or 地下', 階数: 4 },
    その他: { 特色: ['駅近物件', '築浅'], 注記: ['タバコ禁止'] },
    物件種目: '土地 or 土地＋建物（一棟）or 土地＋建物（区分）',
  },
}

export type Purchase = {
  result: PurchaseResult
}

export type PurchaseResult = {
  募集状態: string
  住所: address
  最寄り: {
    路線名: string
    駅名: string
    徒歩時間: string
    バス時間: string
    バス下車徒歩: string
  }[]
  道路: {
    方位: string
    幅員: string
    間口: string
    道路タイプ: string
    条項: string
  }[]
  掲載日: string
  成約日: string
  物件名: string
  建物: {
    構造: string
    竣工年月: string
    総戸数: number
    延床面積: string
    専有面積: string
    敷地面積: string
    有効敷地面積: string
    階数: {
      地上: number
      地下: number
    }
  }
  間取り: string
  エレベータ: string
  オートロック: string
  成約: {
    成約価格: string
    成約表面利回り: string
  }
  売出: {
    売出価格: string
    売出表面利回り: string
  }
  賃料: {
    月割り現況賃料: string
    月割り満室想定賃料: string
  }
  物件タイプ: string
  施工会社: string
  所在階: { 階層: string; 階数: number }
  その他: { 特色: string[]; 注記: string[] }
  物件種目: string
}

export const exampleFormatPurchase: Purchase = {
  result: {
    募集状態: '募集中 or 成約',
    住所: { 都道府県: '東京', 市区町村: '港区', 町名: '北青山', '丁目・その他': '2-7-9' },
    物件名: '東京タワー',
    間取り: '3LDK',
    最寄り: [
      {
        路線名: '京王線',
        駅名: '京王線新宿',
        徒歩時間: '5分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '京王線',
        駅名: '京王線千歳烏山',
        徒歩時間: '',
        バス時間: '10分',
        バス下車徒歩: '5分',
      },
      {
        路線名: '南武線',
        駅名: '府中本町',
        徒歩時間: '20分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '小田急小田原線',
        駅名: '町田',
        徒歩時間: '25分',
        バス時間: '4分',
        バス下車徒歩: '3分',
      },
    ],
    道路: [
      { 方位: '北', 幅員: '5.77m', 間口: '10.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
      { 方位: '南', 幅員: '1.77m', 間口: '3.0m', 道路タイプ: '私道', 条項: '通路' },
      { 方位: '北西', 幅員: '34.77m', 間口: '3.0m', 道路タイプ: '公道', 条項: '42条1項2号' },
      { 方位: '南東', 幅員: '4.77m', 間口: '13.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
    ],
    掲載日: '2023-10-01',
    成約日: '2023-10-02',
    建物: {
      構造: 'rc or wrc or src or s_light or s_heavy or w or al or cft or cb or others',
      階数: { 地上: 5, 地下: 1 },
      竣工年月: '1990-06-01',
      総戸数: 10,
      延床面積: '1000.00m2',
      専有面積: '1000.00m2',
      敷地面積: '122.00m2',
      有効敷地面積: '110.00m2',
    },
    エレベータ: '有り or 無し',
    オートロック: '有り or 無し',
    成約: {
      成約価格: '130000000円',
      成約表面利回り: '3.57%',
    },
    売出: {
      売出価格: '150000000円',
      売出表面利回り: '8.54%',
    },
    賃料: {
      月割り現況賃料: '200000円',
      月割り満室想定賃料: '350000円',
    },
    施工会社: '清水建設',
    物件タイプ: 'ビル',
    所在階: { 階層: '地上 or 地下', 階数: 4 },
    その他: { 特色: ['駅近物件', '築浅'], 注記: ['タバコ禁止'] },
    物件種目: '土地 or 土地＋建物（一棟）or 土地＋建物（区分）',
  },
}

export function chooseFormat(formatType) {
  switch (formatType) {
    case 'acquisition':
      return exampleFormatAcquisition
      break
    case 'rent':
      return exampleFormatRent
      break
    case 'purchase':
      return exampleFormatPurchase
      break
    default:
      return undefined
  }
}
