import '@/components/GoogleMapViewer/style.css'
import { UserContext } from '@/components/Layout/Base'
import { Loading } from '@/components/Loading'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import { Table } from '@/components/Table'
import type { MarketDatum } from '@/types/marketDatum'
import type { LatLng } from '@/types/property'
import type { User } from '@/types/user'
import { cover_enterprise_pricing_type } from '@/utils/policy'
import { GoogleMap } from '@react-google-maps/api'
import * as React from 'react'
import { marketDataReportSetup } from '../marketDataReportSetup'
import MapModal from '../modal'
import { BalloonOverlay, BalloonOverlayImpl } from '../setupButtons/Layer/BalloonOverlay'
import { LayerButton } from '../setupButtons/Layer/LayerButton'
import { MapModeType } from '../setupButtons/Layer/types/MapModeType'
import addSearchBox from '../setupButtons/addSearchBox'
import { setup3Dbutton } from '../setupButtons/setup3Dbutton'
import setupAreaSaveButton from '../setupButtons/setupAreaSaveButton'
import { setupLocationButton } from '../setupButtons/setupLocationButton'
import { setupResetButton } from '../setupButtons/setupResetButton'

interface MapProps {
  polygons_api_base_url?: string
  zoom?: number
  current_user: User
  csrfToken: string
  mode: MapModeType
  property_id?: number
  volume_check_request_id?: number
  lat?: number | string
  lng?: number | string
  propertyLat?: number
  propertyLng?: number
  propertyShape?: LatLng[]
  existdAreaValue?: string
  areaTextFromResponse?: string
  areaShape?: string
  requestUUID?: string
  onChangePolygon?: (changed: boolean) => void
  onChangeAreaCheck?: (changed: boolean) => void
  onRemovePolygon?: (removed: boolean) => void
  ref_volume_check_parameters?: any
  setChibanInfo?: (info: any) => void
  onChangeLatLng?: (latLng: LatLng) => void
  onChangeShape?: (shape: any) => void
  onChangeParameters?: (property: any) => void
  setWideAreaZoom?: (zomm: number) => void
  setNarrowAreaZoom?: (zomm: number) => void
  setShowYoutoInfo?: (show: boolean) => void
  setYoutoInfo?: (key: string, info: any) => void
  setOnLoadComplete?: (complete: boolean) => void
  handleListModeMapChange?: (center: LatLng, zoom: number) => void
  style?: React.CSSProperties
}

export interface MapHandles {
  setLocation(address: string, zoom?: number): void
  setPositionLocation(center: LatLng, zoom: number): void
  setMarketData(
    marketData: MarketDatum[],
    clickMarker: (marketDatum: MarketDatum) => void,
    filteredMarketDatum: MarketDatum
  ): void
  setMarketDataReport(marketData: MarketDatum[]): void
  clearMarketData(): void
}

export const GoogleMapViewer = React.forwardRef<MapHandles, MapProps>(
  (
    {
      polygons_api_base_url = '',
      zoom = 19,
      current_user,
      csrfToken,
      mode,
      property_id,
      lat,
      lng,
      propertyLat,
      propertyLng,
      propertyShape,
      existdAreaValue = '',
      areaTextFromResponse,
      areaShape,
      onChangePolygon,
      onChangeAreaCheck,
      setChibanInfo,
      onChangeLatLng,
      onChangeShape,
      setWideAreaZoom,
      setNarrowAreaZoom,
      setShowYoutoInfo,
      setYoutoInfo,
      setOnLoadComplete,
      handleListModeMapChange,
      style,
    }: MapProps,
    ref
  ) => {
    const mapOptions = React.useMemo(() => {
      return {
        center: { lat: Number(lat), lng: Number(lng) },
        zoom: zoom,
        minZoom: 1,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: true,
        tilt: 0,
        mapTypeId: 'roadmap',
      }
    }, [lat, lng])
    const { user } = React.useContext(UserContext)

    const [balloonOverlay, setBalloonOverlay] = React.useState<BalloonOverlayImpl>()
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [modalProperty, setModalProperty] = React.useState<{ marker?: google.maps.Marker }>({})
    const [showInfoTable, setShowInfoTable] = React.useState(false)
    const [marketDataMarkers, setMarketDataMarkers] = React.useState([])
    const layerButtonRef = React.useRef<LayerButton>(null)
    const [shadeInfos, setShadeInfos] = React.useState({
      shade5m: '-',
      shade10m: '-',
      shadeHeight: '-',
    })
    const [usageInfos, setUsageInfos] = React.useState({
      usageArea: '-',
      buildingCoverageRatio: '-',
      floorAreaRatio: '-',
    })
    const [heightInfos, setHeightInfos] = React.useState({
      heightInfo: '-',
      heightMax: '-',
      heightMin: '-',
    })
    const [antifireInfo, setAntifireInfo] = React.useState('-')

    const mapRef = React.useRef<google.maps.Map>(null)

    const [lastBounds, setLastBounds] = React.useState(null)

    const onBoundsChanged = () => {
      const newBounds = mapRef.current.getBounds()
      if (mode === 'list' && (!lastBounds || !newBounds.equals(lastBounds))) {
        setLastBounds(newBounds)
        updateMapInfo()
      }
    }

    const updateMapInfo = () => {
      if (mapRef.current) {
        const center = mapRef.current.getCenter()
        const zoom = mapRef.current.getZoom()
        handleListModeMapChange({ lat: center.lat(), lng: center.lng() }, zoom)
      }
    }

    React.useEffect(() => {
      if (areaTextFromResponse) {
        layerButtonRef.current.showAreaText(areaShape, areaTextFromResponse)
      }
    }, [areaTextFromResponse])

    React.useImperativeHandle(ref, () => ({
      async setLocation(address, zoom) {
        const geocoder = new google.maps.Geocoder()
        const results = await geocoder.geocode({ address: address, language: 'ja' })
        mapRef?.current.setCenter(results.results[0].geometry.location)
        if (zoom !== undefined) {
          mapRef?.current.setZoom(zoom)
        }
      },
      async setPositionLocation(center, zoom) {
        while (!mapRef.current) {
          await new Promise((resolve) => setTimeout(resolve, 100))
        }
        mapRef?.current.setCenter({ lat: Number(center.lat), lng: Number(lng) })
        mapRef?.current.setZoom(zoom)
      },
      setMarketData(
        marketData: MarketDatum[],
        clickMarker: (marketDatum: MarketDatum, filtered: boolean) => void,
        filteredMarketDatum: MarketDatum
      ) {
        const latlng = mapRef.current.getCenter()
        const bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(latlng.lat(), latlng.lng()),
          new google.maps.LatLng(latlng.lat() + 30, latlng.lng() + 30),
        );

        balloonOverlay.update(bounds, marketData)

        balloonOverlay.onSelected = ((marketDatum, selected) => {
          clickMarker(marketDatum, selected)
        })
      },
      setPing: (address) => {
        const interval = setInterval(() => {
          if (layerButtonRef.current) {
            layerButtonRef.current.setPin(address)
            clearInterval(interval)
          }
        }, 100)
      },
      setMarketDataReport(marketData: MarketDatum[]) {
        // Mapに周辺事例のピンを表示する
        const markers = marketDataReportSetup(mapRef?.current, marketData)

        const latlng = mapRef.current.getCenter()
        const bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(latlng.lat(), latlng.lng()),
          new google.maps.LatLng(latlng.lat() + 10, latlng.lng() + 10),
        );

        balloonOverlay.update(bounds, marketData)
        balloonOverlay.reset(mapRef.current)

        // タブ切替時に表示していたピンのクリアを行うため保持しておく
        setMarketDataMarkers(markers)
      },
      clearMarketData() {
        // タブ切り替え前に表示していた周辺事例のピンをクリアする
        marketDataMarkers.forEach((marker) => {
          marker.setMap(null)
        })

        setMarketDataMarkers([])
        balloonOverlay.setMap(null)
      },
    }))

    const [currentLocationMarker, setCurrentLocationMarker] = React.useState(null)
    const loading = <Loading height={20} width={20} />

    const onLoad = (map) => {
      mapRef.current = map

      if (setOnLoadComplete) {
        // mapRef.currentが取得できることを通知
        setOnLoadComplete(true)
      }

      const layerButton = new LayerButton({
        map,
        mode,
        user: current_user,
        lat: Number(lat),
        lng: Number(lng),
        polygons_api_base_url,
        property_id,
        propertyLat,
        propertyLng,
        propertyShape,
        areaTextFromResponse,
        siteArea: existdAreaValue,
        areaShape,
        csrfToken,
      })
      layerButton.emitter.on('setShowInfoTable', setShowInfoTable)
      layerButton.emitter.on('setUsageInfos', setUsageInfos)
      layerButton.emitter.on('setAntifireInfo', setAntifireInfo)
      layerButton.emitter.on('setHeightInfos', setHeightInfos)
      layerButton.emitter.on('setShadeInfos', setShadeInfos)
      layerButton.emitter.on('setYoutoInfo', setYoutoInfo ? setYoutoInfo : (_) => { })
      layerButton.emitter.on('setShowYoutoInfo', setShowYoutoInfo ? setShowYoutoInfo : (_) => { })
      layerButton.emitter.on('setChibanInfo', setChibanInfo ? setChibanInfo : (_) => { })

      layerButton.propertyMarkerEmitterOn('setModalProperty', setModalProperty)
      layerButton.propertyMarkerEmitterOn('setIsModalOpen', setIsModalOpen)
      layerButton.propertyMarkerEmitterOn('onChangeShape', onChangeShape)
      layerButton.propertyMarkerEmitterOn('onChangePolygon', onChangePolygon)
      layerButton.propertyMarkerEmitterOn('onChangeAreaCheck', onChangeAreaCheck)
      layerButton.propertyMarkerEmitterOn('onChangeLatLng', onChangeLatLng)

      layerButtonRef.current = layerButton

      const balloonOverlay = BalloonOverlay(mapRef.current)
      balloonOverlay.setMap(mapRef.current);
      setBalloonOverlay(balloonOverlay)

      const styledMapType = new google.maps.StyledMapType(
        [
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit.line', // 交通機関の路線
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
          {
            featureType: 'transit.station.rail', // 電車の駅
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
        ],
        { name: 'Styled Map' }
      )

      map.mapTypes.set('styled_map', styledMapType)

      map.setMapTypeId('styled_map')

      // MARK: リセットボタン
      setupResetButton(map, () => {
        // ピンや敷地形状をクリア
        layerButton.clearProperty()
        // ピンや敷地形状を初期表示に戻す
        layerButton.renderProperty()
        // 呼び出し側の値も初期値に戻す
        if (propertyLat && propertyLng) {
          onChangeLatLng({ lat: propertyLat, lng: propertyLng })
        }
        if (propertyShape) {
          onChangeShape(JSON.stringify(propertyShape))
        }

        balloonOverlay.reset(map)
      })

      // MARK: 3D表示ボタン
      setup3Dbutton(map)

      // MARK: 物件ピンの追加
      layerButton.renderProperty()

      // MARK: 現在地ボタン
      setupLocationButton(map, () => {
        navigator.geolocation.getCurrentPosition((position) => {
          const gpslat = position.coords.latitude
          const gpslng = position.coords.longitude
          const latlng = new google.maps.LatLng(gpslat, gpslng)
          map.setCenter(latlng)

          if (cover_enterprise_pricing_type(current_user)) {
            if (currentLocationMarker) {
              currentLocationMarker.setMap(null)
            }

            const marker = new google.maps.Marker({
              map: map,
              position: new google.maps.LatLng(latlng),
              icon: {
                url: '/flag_icon/current_location.svg',
                scaledSize: new google.maps.Size(30, 60),
              },
              optimized: false,
            })

            setCurrentLocationMarker(marker)
          }
        })
      })

      // MARK: 検索
      if (mode === 'list') {
        addSearchBox(map)
      }

      // MARK: 敷地の広域と狭域のズームを保存
      if (mode === 'property') {
        setupAreaSaveButton(map, setWideAreaZoom, setNarrowAreaZoom)
      }

      google.maps.event.addListener(map, 'idle', () => {
        layerButton.idle()
      })

      // レイヤーボタン群の描画
      layerButton.setupView()
    }

    const containerStyle = React.useMemo(() => {
      return (
        style ?? {
          width: '100%',
          height: '600px',
        }
      )
    }, [style])

    React.useEffect(() => {
      const element = document.getElementById('showInfoTable')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, [showInfoTable])

    React.useEffect(() => {
      const interval = setInterval(() => {
        const pinElement = document.querySelector<HTMLButtonElement>("button[title='描画をやめる']")
        if (pinElement) {
          pinElement.ariaLabel = 'ピンで示す'
          pinElement.title = 'ピンで示す'
        }

        const polygonElement =
          document.querySelector<HTMLButtonElement>("button[title='図形を描画']")
        if (polygonElement) {
          polygonElement.ariaLabel = '敷地を描画'
          polygonElement.title = '敷地を描画'
        }
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }, [])

    return (
      <>
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={mapOptions}
          onLoad={onLoad}
          onBoundsChanged={onBoundsChanged}
        />
        {(mode === 'property' || mode === 'list' || mode === 'market_datum_report') && (
          <MapModal
            open={isModalOpen}
            property={modalProperty}
            current_user={current_user}
            closeModal={(removeMarker: boolean = false, reload: boolean = false) => {
              // マーカーを消去
              if (removeMarker) {
                modalProperty.marker.setMap(null)
              }

              if (reload) {
                layerButtonRef.current.reloadMarker()
              }
              setIsModalOpen(false)
            }}
          />
        )}
        {showInfoTable && (
          <>
            <Table className="border-t border-b border-[#3885B0]">
              <div id="showInfoTable">
                <Row label={<Th left={true}>用途地域</Th>}>
                  <Td>{usageInfos.usageArea == '取得中' ? loading : usageInfos.usageArea}</Td>
                </Row>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        建ぺい率
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {usageInfos.buildingCoverageRatio == '取得中'
                        ? loading
                        : usageInfos.buildingCoverageRatio}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        容積率
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {usageInfos.floorAreaRatio == '取得中' ? loading : usageInfos.floorAreaRatio}
                    </Td>
                  </Row>
                </div>
                <Row label={<Th left={true}>防火地域</Th>}>
                  <Td>{antifireInfo == '取得中' ? loading : antifireInfo}</Td>
                </Row>
                <Row label={<Th left={true}>高度地区</Th>}>
                  <Td>{heightInfos.heightInfo == '取得中' ? loading : heightInfos.heightInfo}</Td>
                </Row>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        最高高度
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMax == '取得中' ? loading : heightInfos.heightMax}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        最低高度
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMin == '取得中' ? loading : heightInfos.heightMin}
                    </Td>
                  </Row>
                </div>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        日影範囲5M超
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMax == '取得中' ? loading : shadeInfos.shade5m}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        日影範囲10M超
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMin == '取得中' ? loading : shadeInfos.shade10m}
                    </Td>
                  </Row>
                </div>
                <Row label={<Th left={true}>日影測定水平面</Th>}>
                  <Td>{heightInfos.heightInfo == '取得中' ? loading : shadeInfos.shadeHeight}</Td>
                </Row>
              </div>
            </Table>
            <div className="block text-xs px-4 pt-1 pb-2 whitespace-normal text-primary font-small">
              ※ 情報データが提供されていない地域の場合は、表内に"N/A"と表示されます。
            </div>
          </>
        )}
      </>
    )
  }
)
