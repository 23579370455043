import * as React from 'react'

import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import type { Property } from '@/types/property'
import type { User } from '@/types/user'
import {
  LatDefault,
  LngDefault,
} from '@/models/property'

import { GoogleMapViewer, MapHandles } from '@/components/GoogleMapViewer/PropertyPublicFiles'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { useJsApiLoader } from '@react-google-maps/api'
import type { LatLng } from '@/types/property'


type Props = {
  google_maps_api_key: string
  property: Property
  error_messages: string[]
}

type LatLng = number[]

const MapPage: React.FC<Props> = ({
  google_maps_api_key,
  property,
  error_messages,
}) => {
  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  const mapRef = React.useRef<MapHandles>(null)
  const latLng: LatLng = {
    lat: property?.address_geocoding[0] || LatDefault,
    lng: property?.address_geocoding[1] || LngDefault,
  }
  const dataLatLng: LatLng = {
    lat: property?.address_geocoding[0],
    lng: property?.address_geocoding[1],
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: ['geometry', 'drawing', 'places'],
  })
  const propertyShape = property?.shape?.latlngs.map(latlng => ({ lat: latlng.lat, lng: latlng.lng }))

  return (
    <div className="mt-4">
      {isLoaded && (
        <GoogleMapViewer
          csrfToken={csrfToken.content}
          property_id={Number(property?.id)}
          lat={latLng.lat}
          lng={latLng.lng}
          propertyLat={dataLatLng.lat}
          propertyLng={dataLatLng.lng}
          propertyShape={propertyShape}
          ref={mapRef}
          style={{
            width: '100%',
            height: 'auto',
            aspectRatio: '1/1',
          }}
        />
      )}
    </div>
  )
}

export default MapPage
