import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import type { User } from '@/types/user'
import type { Company } from '@/types/company'
import { isOverEnterprise } from '@/types/company'
import { PricingTypeName } from '@/models/pricingType'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { RadioButton } from '@/components/RadioButton'
import { Button, AnchorButton } from '@/components/Button'
import { prefectureOptions } from '@/models/prefecture'
import { pricingTypeOptions } from '@/models/pricingType'
import { CloseIcon } from '@/components/SvgIcon'
import { pricingType as pricingTypeText } from '@/config/langs/company'
import { Breadcrumb } from '@/components/Breadcrumb'
import Tooltip from '@mui/material/Tooltip'

type Props = {
  current_user: User
  company: Company
  authenticity_token: string
  error_messages: string[]
}

const validateRegisterIp = (newIp: string): boolean => {
  if (
    !newIp.match(
      /^([12]\d{2}|[1-9]\d|[0-9])\.([12]\d{2}|[1-9]\d|[0-9])\.([12]\d{2}|[1-9]\d|[0-9])\.([12]\d{2}|[1-9]\d|[0-9])$/
    )
  ) {
    alert('IPアドレスの形式が不正です')
    return false
  }
  return true
}

const deleteIp = (deleteIp: string): boolean => {
  return confirm(`${deleteIp}を削除してもよろしいですか？`)
}

const CompaniesEditPage: React.FC<Props> = ({
  current_user,
  company,
  authenticity_token,
  error_messages,
}) => {
  const [name, setName] = React.useState(company.name ?? '')
  const [pricingType, setPricingType] = React.useState<PricingTypeName>(
    company.pricing_type ?? 'basic'
  )
  const [zipcode, setZipcode] = React.useState(String(company.zipcode ?? ''))
  const [prefecture, setPrefecture] = React.useState(company.prefecture ?? '')
  const [city, setCity] = React.useState(company.city ?? '')
  const [address, setAddress] = React.useState(company.address ?? '')
  const [phoneNumber, setPhoneNumber] = React.useState(String(company.phone_number ?? ''))
  const [newGlobalIp, setNewGlobalIp] = React.useState('')
  const [fileName, setFileName] = React.useState(company?.logo_image?.blob?.filename ?? '')
  const [previewImage, setPreviewImage] = React.useState<string | null>(null)
  const [subdomain, setSubdomain] = React.useState(company.subdomain ?? '')
  const [limitDisabled, setLimitDisabled] = React.useState(company.pricing_type === 'owner')
  const limitFree = 10
  const limitEnterprise = 50
  const initialLimit =
    company.file_capacity?.limit || (pricingType === 'enterprise' ? limitEnterprise : limitFree)
  const limitDefault = limitDisabled ? '-' : initialLimit
  const [limit, setLimit] = React.useState(limitDefault)
  const [hideSource, setHideSource] = React.useState<
    'source_visible' | 'source_hidden' | 'source_all_visible'
  >(company.hide_source)
  const [ocrEnabled, setOcrEnabled] = React.useState(company.ocr_enabled ?? false)
  const [toukiEnabled, setToukiEnabled] = React.useState(company.touki_enabled ?? false)
  const [isIpRegistered, setIsIpRegistered] = React.useState(company.is_ip_restricted ?? false)
  const [isVolumeCheck, setIsVolumeCheck] = React.useState(company.is_volume_check ?? false)
  const [accountMax, setAccountMax] = React.useState(company.account_max ?? 0)

  // バリデーションに引っかかった場合に文字列を配列に変換する
  const global_ip =
    company.global_ip === null
      ? []
      : Array.isArray(company.global_ip)
      ? company.global_ip
      : String(company.global_ip)
          .replace(/[[\]\"]/g, '')
          .split(',')
  const [globalIps, setGlobalIps] = React.useState(global_ip ?? [])

  const title = '利用企業編集・登録'

  const toggleFileCapacityLimit = (pricingType: string) => {
    if (pricingType === 'owner') {
      setLimitDisabled(true)
      setLimit('-')
    } else if (pricingType === 'enterprise') {
      setLimitDisabled(false)
      setLimit(limitEnterprise)
    } else {
      setLimitDisabled(false)
      setLimit(limitFree)
    }
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['利用企業設定', '利用企業編集・登録']} />}
      submenuCategory="settings"
    >
      <form
        className="mt-8"
        encType="multipart/form-data"
        action={company.id ? `/companies/${company.id}` : '/companies'}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value={company.id ? 'patch' : 'post'} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}
        <Table className="block h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <tbody className="table-row-group">
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                会社名
              </th>
              <td className="py-2 px-4 w-3/4 table-cell">
                <Input
                  className="md:max-w-[246px]"
                  value={name}
                  name="company[name]"
                  id="company_name"
                  onChange={(e) => setName(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-white table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                料金タイプ
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {current_user.role === 'system_admin' &&
                current_user.company.pricing_type === 'owner' ? (
                  <div className="flex gap-4">
                    {pricingTypeOptions
                      .filter((v) =>
                        ['basic', 'standard', 'enterprise', 'owner'].includes(v.value)
                      )
                      .map((item, i) => (
                        <RadioButton
                          key={i}
                          name="company[pricing_type]"
                          id={`company_pricing_type_${i}`}
                          text={item.text}
                          value={item.value}
                          checked={item.value === pricingType}
                          onChange={(e) => {
                            setPricingType(e.target.value)
                            toggleFileCapacityLimit(e.target.value)
                          }}
                        />
                      ))}
                  </div>
                ) : (
                  <div className="py-2 px-4">{pricingTypeText[company.pricing_type]}</div>
                )}
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                契約容量（GB）
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {current_user.role === 'system_admin' ? (
                  <>
                    <Input
                      className="md:max-w-[246px]"
                      value={limit}
                      disabled={limitDisabled}
                      name="company[file_capacity_attributes][limit]"
                      id="company_file_capacity_attributes_limit"
                      onChange={(e) => setLimit(event.target.value)}
                    />
                    {company.file_capacity?.id && (
                      <input
                        type="hidden"
                        value={company.file_capacity.id}
                        name="company[file_capacity_attributes][id]"
                        id="company_file_capacity_attributes_id"
                      />
                    )}
                  </>
                ) : (
                  <span className="py-2 px-4">{limit}</span>
                )}
              </td>
            </tr>
            <tr className="bg-white table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                郵便番号
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <Input
                  className="md:max-w-[246px]"
                  value={zipcode}
                  name="company[zipcode]"
                  id="company_zipcode"
                  onChange={(e) => setZipcode(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                都道府県
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <Select
                  className="md:max-w-[246px]"
                  name="company[prefecture]"
                  id="company_prefecture"
                  value={prefecture}
                  options={prefectureOptions.map((i) => ({ value: i.value, text: i.label }))}
                  onChange={(e) => setPrefecture(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-white table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                市区町村
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <Input
                  className="md:max-w-[246px]"
                  name="company[city]"
                  id="company_city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                その他住所
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <Input
                  className="md:max-w-[246px]"
                  name="company[address]"
                  id="company_address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-white table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                <Tooltip title="「ログイン制限」を「あり」に設定すると、事前に登録された特定のグローバルIPアドレスからのみログインが可能になります。利用端末のIPアドレスがグローバルIPアドレスリストと一致しない場合は、システムへのログインができなくなります。">
                  <span className="cursor-help border-b border-dashed border-black">
                    ログイン制限
                  </span>
                </Tooltip>
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {current_user.role === 'system_admin' || current_user.role === 'admin' ? (
                  <div className="flex gap-4">
                    <RadioButton
                      name="company[is_ip_restricted]"
                      id="company_is_ip_restricted_true"
                      text="あり"
                      value="true"
                      checked={isIpRegistered === true}
                      onClick={() => setIsIpRegistered(true)}
                    />
                    <RadioButton
                      name="company[is_ip_restricted]"
                      id="company_is_ip_restricted_false"
                      text="なし"
                      value="false"
                      checked={isIpRegistered === false}
                      onClick={() => setIsIpRegistered(false)}
                    />
                  </div>
                ) : (
                  <span className="py-2 px-4">{company.is_ip_restricted ? 'あり' : 'なし'}</span>
                )}
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                グローバルIPアドレス
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <input
                  type="hidden"
                  name="company[global_ip]"
                  value={`[${globalIps.map((ip) => `"${ip}"`).join(',')}]`}
                />
                <div className="flex">
                  <Input
                    className="w-full md:w-[246px] mr-6"
                    placeholder="111.222.33.44"
                    value={newGlobalIp}
                    onChange={(e) => setNewGlobalIp(e.target.value)}
                  />
                  <Button
                    className="text-primary-font"
                    outline
                    size="small"
                    onClick={(e) => {
                      e.preventDefault()
                      if (!validateRegisterIp(newGlobalIp)) return
                      setGlobalIps([...globalIps, newGlobalIp])
                      setNewGlobalIp('')
                    }}
                  >
                    追加
                  </Button>
                </div>
                {globalIps.map((ip, ipIndex) => (
                  <div key={ipIndex} className="mt-4">
                    <div className="md:inline-flex relative">
                      <Input
                        className="w-full md:w-[246px] pointer-events-none"
                        placeholder="111.222.33.44"
                        value={ip}
                        readOnly
                      />
                      <button
                        className="absolute top-1/2 -translate-y-1/2 right-4"
                        onClick={(e) => {
                          e.preventDefault()
                          if (!deleteIp(ip)) return
                          setGlobalIps([...globalIps].filter((v) => v !== ip))
                        }}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  </div>
                ))}
              </td>
            </tr>
            <tr className="bg-white table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                電話番号
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <Input
                  className="md:max-w-[246px]"
                  name="company[phone_number]"
                  id="company_phone_number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                <Tooltip
                  title={
                    <span>
                      システムに登録されているグローバルIPアドレスからのログインかに応じて、入手先の表示範囲を変更します。
                      <br />
                      ・表示　　…　登録IPアドレス内→全て見れる／外→自分が情報入手者になっている入手先だけ見れる。
                      <br />
                      ・非表示　…　登録IPアドレス内／外ともに→自分が情報入手者になっている入手先だけ見れる。
                      <br />
                      ・全表示　…　登録IPアドレス内／外ともに→全て見れる。
                    </span>
                  }
                >
                  <span className="cursor-help border-b border-dashed border-black">
                    入手先の表示
                  </span>
                </Tooltip>
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <div className="flex gap-4">
                  <RadioButton
                    name="company[hide_source]"
                    id="company_hide_source_visible"
                    text="表示"
                    value="source_visible"
                    checked={hideSource === 'source_visible'}
                    onClick={() => setHideSource('source_visible')}
                  />
                  <RadioButton
                    name="company[hide_source]"
                    id="company_hide_source_hidden"
                    text="非表示"
                    value="source_hidden"
                    checked={hideSource === 'source_hidden'}
                    onClick={() => setHideSource('source_hidden')}
                  />
                  <RadioButton
                    name="company[hide_source]"
                    id="company_hide_source_all_visible"
                    text="全表示"
                    value="source_all_visible"
                    checked={hideSource === 'source_all_visible'}
                    onClick={() => setHideSource('source_all_visible')}
                  />
                </div>
              </td>
            </tr>
            <tr className="bg-white-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                生成AI-OCR読取
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {current_user.role === 'system_admin' &&
                current_user.company.pricing_type === 'owner' ? (
                  <div className="flex gap-4">
                    <RadioButton
                      name="company[ocr_enabled]"
                      id="company_ocr_enabled_true"
                      text="あり"
                      value="true"
                      checked={ocrEnabled === true}
                      disabled={!isOverEnterprise(pricingType)}
                      onClick={() => setOcrEnabled(true)}
                    />
                    <RadioButton
                      name="company[ocr_enabled]"
                      id="company_ocr_enabled_false"
                      text="なし"
                      value="false"
                      checked={ocrEnabled === false}
                      onClick={() => setOcrEnabled(false)}
                    />
                  </div>
                ) : (
                  <span className="py-2 px-4">{company.ocr_enabled ? 'あり' : 'なし'}</span>
                )}
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                登記情報取得
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {current_user.role === 'system_admin' &&
                current_user.company.pricing_type === 'owner' ? (
                  <div className="flex gap-4">
                    <RadioButton
                      name="company[touki_enabled]"
                      id="company_touki_enabled_true"
                      text="あり"
                      value="true"
                      checked={toukiEnabled === true}
                      disabled={!isOverEnterprise(pricingType)}
                      onClick={() => setToukiEnabled(true)}
                    />
                    <RadioButton
                      name="company[touki_enabled]"
                      id="company_touki_enabled_false"
                      text="なし"
                      value="false"
                      checked={toukiEnabled === false}
                      onClick={() => setToukiEnabled(false)}
                    />
                  </div>
                ) : (
                  <span className="py-2 px-4">{company.touki_enabled ? 'あり' : 'なし'}</span>
                )}
              </td>
            </tr>
            <tr className="bg-white-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                volume check
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {current_user.role === 'system_admin' ? (
                  <div className="flex gap-4" style={{ alignItems: 'center' }}>
                    <RadioButton
                      name="company[is_volume_check]"
                      id="company_is_volume_check_true"
                      text="あり"
                      value="true"
                      checked={isVolumeCheck === true}
                      disabled={!isOverEnterprise(pricingType)}
                      onClick={() => setIsVolumeCheck(true)}
                    />
                    <RadioButton
                      name="company[is_volume_check]"
                      id="company_is_volume_check_false"
                      text="なし"
                      value="false"
                      checked={isVolumeCheck === false}
                      onClick={(e) => {
                        setIsVolumeCheck(false)
                        setAccountMax(0)
                      }}
                    />
                    <div>
                      <Input
                        className="md:max-w-[246px]"
                        name="company[account_max]"
                        id="company_account_max"
                        value={accountMax}
                        suffix="アカウント"
                        prefix="契約数"
                        type="number"
                        inputMode="numeric"
                        disabled={isVolumeCheck === false || !isOverEnterprise(pricingType)}
                        min="0"
                        onChange={(e) => setAccountMax(parseInt(e.target.value))}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <span className="py-2 px-4">{company.is_volume_check ? 'あり' : 'なし'}</span>
                    {company.is_volume_check && (
                      <span className="py-2 px-4">{company.account_max} アカウント</span>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                ロゴ画像
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {previewImage && (
                  <img className="w-full md:w-40 h-auto mb-2" src={previewImage} alt="" />
                )}
                <div className="md:flex">
                  <Input
                    className="mb-4 md:mb-0 md:w-[246px] md:mr-6 pointer-events-none"
                    placeholder="選択されていません"
                    readOnly
                    value={fileName}
                  />
                  <label htmlFor="company_logo_image" className={styles.fileButton}>
                    <input
                      type="file"
                      accept="image/*"
                      name="company[logo_image]"
                      id="company_logo_image"
                      className="absolute w-0 h-0 opacity-0 hidden"
                      onChange={(e) => {
                        const file = e.target.files[0]
                        setFileName(file.name)
                        const reader = new FileReader()

                        reader.onload = () => {
                          const imageData = reader.result as string
                          setPreviewImage(imageData)
                        }
                        reader.readAsDataURL(file)
                      }}
                    />
                    ファイルをアップロード
                  </label>
                </div>
              </td>
            </tr>
            <tr className="bg-white-light table-row">
              <th
                scope="row"
                className="p-0 w-0 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell"
              ></th>
              <td className="text-xs py-2 px-4 md:w-3/4 table-cell text-gray-500">
                登録可能な画像形式：PNG, JPG
                <br />
                登録可能な画像サイズ(ピクセル)：横800～810 x 縦200～210
              </td>
            </tr>
            {current_user.role === 'system_admin' &&
              current_user.company.pricing_type === 'owner' && (
                <tr className="bg-gray-light table-row">
                  <th
                    scope="row"
                    className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
                  >
                    サブドメイン
                  </th>
                  <td className="py-2 px-4 md:w-3/4 table-cell">
                    <Input
                      className="md:max-w-[246px]"
                      name="company[subdomain]"
                      id="company_subdomain"
                      value={subdomain}
                      onChange={(e) => setSubdomain(e.target.value)}
                      suffix=".smart-land.jp"
                    />
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            variant="text"
            outline
            href="/companies"
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

export default CompaniesEditPage

const styles = {
  fileButton: `
    flex
    md:inline-flex
    align
    items-center
    justify-center
    gap-1.5
    rounded-sm
    leading-none
    duration-200
    transition-opacity
    hover:opacity-80
    rounded-sm
    border
    box-border
    border-primary-font
    text-primary-font
    py-[5px]
    md:py-[7px]
    px-4
    text-sm
    cursor-pointer
  `,
}
