import * as React from "react"
import "./Loading.css"
import { Button, AnchorButton } from "@/components/Button";
import { Input } from '@/components/Input'
import {dataPrecisionSetup} from "@/utils/cammedFormat";

interface Props {
  loadMessage: string
  points: any
  types: string
  setLoading
  setLines
  setPreviewImage
  setLoadedImage
  setErrorMessage
  setIsImageLoaded
  setIsZoom
  setPlottingMode
  setMeasuringScale
  setLoadMessage
  clearDrawing
  clearSelection
  setParamsJson
  setValue
  measuringScale
  formRef
  handleSubmit
  setOutLinesTmp
}
const Loading: React.FC<Props> = ({
                                    loadMessage,
                                    points, types,
                                    setLoading,
                                    setLines,
                                    setPreviewImage,
                                    setLoadedImage,
                                    setErrorMessage,
                                    setIsImageLoaded,
                                    setIsZoom,
                                    setPlottingMode,
                                    setMeasuringScale,
                                    setLoadMessage,
                                    clearDrawing,
                                    clearSelection,
                                    setParamsJson,
                                    setValue,
                                    measuringScale,
                                    formRef,
                                    handleSubmit,
                                    setOutLinesTmp
                                  }) => {

  const [translateLeft, setTranslateLeft] = React.useState(0)
  const [translateTop, setTranslateTop] = React.useState(0)
  const [centerX, setCenterX] = React.useState(0)
  const [centerY, setCenterY] = React.useState(0)
  const [scale, setScale] = React.useState(1)
  const [positionList, setPositionList] = React.useState(types !== '1' && points.length > 0 ? points[0]: [])
  const [shapeColors, setShapeColors] = React.useState({});
  const selectIndex= React.useRef([])
  const [outLines, setOutLines] = React.useState(null)
  const [shapeFlg, setShapeFlg] = React.useState(false)


  React.useEffect(() => {
    if (points.length > 0 && types === '1') {
      setPositionList(points)
    } else if (points.length > 0) {
      const tmp = []
      for (let i = 0; i < points.length; i++) {
        tmp.push({value: i, text: i + 1})
      }
      if (tmp.length > 0) {
        setPositionList(points)
      }
    }
  },[points])
  React.useEffect(() => {
    if (positionList.length > 0) {
      const filteredPositionList = positionList.filter(shape => shape.length > 0);
      if (filteredPositionList.length > 0) {
        const minX = Math.min(...filteredPositionList.flatMap(shape =>
            shape.flatMap(point => [parseFloat(point.start.x), parseFloat(point.end.x)])
        ));
        const minY = Math.min(...filteredPositionList.flatMap(shape =>
            shape.flatMap(point => [parseFloat(point.start.y), parseFloat(point.end.y)])
        ));
        const maxX = Math.max(...filteredPositionList.flatMap(shape =>
            shape.flatMap(point => [parseFloat(point.start.x), parseFloat(point.end.x)])
        ));
        const maxY = Math.max(...filteredPositionList.flatMap(shape =>
            shape.flatMap(point => [parseFloat(point.start.y), parseFloat(point.end.y)])
        ));
        const width = maxX - minX
        const height = maxY - minY
        const a = Math.min(width, height)
        const scaleTmp = Number((250 / a).toFixed(2))
        setScale(scaleTmp)
        const translateLeftTmp = -scaleTmp * minX
        const translateTopTmp = scaleTmp * minY
        setTranslateLeft(translateLeftTmp)
        setTranslateTop(translateTopTmp)
        const svgWidth = window.innerWidth - 10
        const svgHeight = window.innerHeight - 10
        const centerXTmp = (svgWidth - width * scaleTmp) / 2
        const centerYTmp = (svgHeight + height * scaleTmp) / 2
        setCenterX(centerXTmp)
        setCenterY(centerYTmp)
      }
    }
  }, [positionList])

  const handleShapeClick = (shapeIdx) => {
    setShapeColors((prevColors) => ({
      ...prevColors,
      [shapeIdx]: prevColors[shapeIdx] === 'lightblue' ? 'white' : 'lightblue',
    }));
    if (shapeColors[shapeIdx] === 'lightblue') {
      selectIndex.current = selectIndex.current.filter(item => item !== shapeIdx)
    } else {
      selectIndex.current.push(shapeIdx)
    }
    if (selectIndex.current.length === 1) {
      const positionListTmp = []
      const tmp = positionList[selectIndex.current[0]]
      const outLinesList = []
      for (const subArray of positionList) {
        for (const element of subArray) {
          positionListTmp.push(element);
        }
      }
      tmp.forEach((item) => {
        outLinesList.push(positionListTmp.findLastIndex(items => items.start.x === item.start.x && items.start.y === item.start.y) + 1)
      })
      setOutLines(outLinesList.join(","))
    } else {
      setOutLines("")
    }
  };

  return (
      <div className="loading-container">
        {positionList.length === 0? (<>
          {(loadMessage === "データ正しく取得できませんでした。改めて試してください。" || loadMessage === "システムエラーのため、改めて試してください。") ? (<></>): (
              <div className="loading-dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
          )}
          {loadMessage === "データ正しく取得できませんでした。改めて試してください。" || loadMessage === "システムエラーのため、改めて試してください。" ? (
                  <>
                    <div className="loading-text-red loading-container px-8">
                      <span className="mt-10">
                        {loadMessage}
                      </span>
                      <div style={{height: '150px'}}></div>
                      <Button
                          className="mb-10"
                          onClick={() => {
                            setLoading(false)
                            setPreviewImage(null)
                            setLoadedImage([])
                            setErrorMessage(false)
                            setIsImageLoaded(false)
                            setIsZoom(false)
                            setPlottingMode("")
                            setValue("0")
                            setMeasuringScale("")
                            setLoadMessage("処理中…")
                            clearDrawing()
                            clearSelection()
                            setParamsJson(false)
                          }}
                      >
                        閉じる
                      </Button>
                    </div>
                  </>
              ) :
              (
                  <div className="loading-text">{loadMessage}</div>
              )}
        </>) : (
          <>
            <svg width={window.innerWidth - 10} height={window.innerHeight - 10} style={{}}>
              <g style={{transformOrigin: "0px 0px 0px", transform: `translate(${centerX + translateLeft}px, ${centerY + translateTop}px) scale(${scale})`}}>
                {(() => {
                  let vertexCounter = 1;
                  let displayedPoints = new Map();

                  return (
                      <>
                        {positionList.map((shape, shapeIdx) => {
                          const points = shape.map((m) => `${m.start.x},${m.start.y}`).join(" ");
                          return (
                              <polygon
                                  key={shapeIdx}
                                  points={points}
                                  fill={shapeColors[shapeIdx] || "white"}
                                  stroke="red"
                                  strokeWidth={1}
                                  transform="scale(1, -1)"
                                  onClick={() => handleShapeClick(shapeIdx)}
                              />
                          );
                        })}

                        {positionList.map((shape, shapeIdx) => {
                          return shape.map((m, idx) => {
                            const pointKey = `${m.start.x}-${m.start.y}`;
                            const fontSize = 14;
                            const elementId = `point-${m.start.x}-${m.start.y}`;

                            return (
                                <React.Fragment key={idx}>
                                  <image
                                      href="/map_flag.png"
                                      x={(m.start.x) - 7 / scale}
                                      y={(-m.start.y) - 28 / scale}
                                      width={32 / scale}
                                      height={32 / scale}
                                  />
                                  <text
                                      id={elementId}
                                      x={(m.start.x) + 7 / scale}
                                      y={(-m.start.y) - 14 / scale}
                                      fontSize={fontSize / scale}
                                      fill="white"
                                      textAnchor="middle"
                                      alignmentBaseline="middle"
                                  >
                                    {vertexCounter}
                                  </text>
                                  {displayedPoints.set(pointKey, elementId)}
                                  {vertexCounter++}
                                </React.Fragment>
                            );
                          });
                        })}
                      </>
                  );
                })()}
              </g>
            </svg>
            <div className="flex items-center mb-4" style={{position: 'absolute', bottom: '0'}}>
              {!shapeFlg?(
                  <>
                    <span className="mr-4">形状輪郭指定</span>
                    <Input
                        className="mr-4 md:w-[320px]"
                        placeholder="フラッグ番号により輪郭を指定してください"
                        value={outLines}
                        onChange={(e) => {
                          const value = e.target.value
                          const regex = /^[0-9,\-]*$/
                          const lastChar = value.charAt(value.length - 1)
                          if (regex.test(value)) {
                            setOutLines(e.target.value)
                          } else if (!lastChar || !regex.test(lastChar)) {
                            e.target.value = value.slice(0, -1)
                          }
                        }}
                        onBlur={(e) => {
                          let value = e.target.value
                          value = value.replace(/,+/g, ',').replace(/^,|,$/g, '')
                          value = value.replace(/-+/g, '-')
                          value = value.replace(/-$/, '')
                          value = value.trim()
                          setOutLines(value)
                        }}
                    />
                    <Button
                        type="button"
                        className="mr-4"
                        disabled={!outLines}
                        onClick={() => {
                          let tmp = []
                          let positionListTmp = []
                          const indexs = outLines.split(',').map(Number)
                          for (const subArray of positionList) {
                            for (const element of subArray) {
                              positionListTmp.push(element);
                            }
                          }
                          indexs.forEach((item) => {
                            tmp.push(positionListTmp[item - 1])
                          })
                          tmp = tmp.map((item, index) => {
                            if (index === tmp.length - 1) {
                              return {
                                start: {x: item.start.x, y: -item.start.y},
                                end: {x:tmp[0].start.x, y: -tmp[0].start.y}
                              }
                            } else {
                              return {
                                start: {x: item.start.x, y: -item.start.y},
                                end: {x:tmp[index + 1].start.x, y: -tmp[index + 1].start.y}
                              }
                            }
                          })
                          setOutLinesTmp(tmp)
                          setShapeFlg(true)
                        }}
                    >
                      形状輪郭確定
                    </Button>
                    <AnchorButton
                        className="bg-white"
                        type="button"
                        outline={true}
                        onClick={() => {
                          setLoading(false)
                          setPreviewImage(null)
                          setLoadedImage([])
                          setErrorMessage(false)
                          setIsImageLoaded(false)
                          setIsZoom(false)
                          setPlottingMode("")
                          setValue("0")
                          setMeasuringScale("")
                          setLoadMessage("処理中…")
                          clearDrawing()
                          clearSelection()
                          setParamsJson(false)
                        }}
                    >
                      キャンセル
                    </AnchorButton>
                  </>
              ) : (
                  <>
                    <span className="mr-4">点1-点2の長さ</span>
                    <Input
                        className="mr-4"
                        suffix="m"
                        value={measuringScale}
                        placeholder="点1-点2の実際の長さの数値入力"
                        onChange={(e) => {
                          setMeasuringScale(e.target.value)
                        }}
                        onBlur={(e) => {
                          const value = dataPrecisionSetup(e.target.value, 12, 3)
                          setMeasuringScale(value.toString())
                        }}
                    />
                    <Button
                        className="mr-4"
                        disabled={!measuringScale}
                        onClick={(e) => {
                          setLoading(false)
                          e.preventDefault()
                          handleSubmit()
                          formRef.current?.submit()
                        }}
                    >
                      形状確定
                    </Button>
                    <AnchorButton
                        className="bg-white"
                        type="button"
                        outline={true}
                        onClick={() => {
                          setShapeFlg(false)
                          setMeasuringScale("")
                        }}
                    >
                      キャンセル
                    </AnchorButton>
                  </>
              )}
            </div>
          </>
        )}
      </div>
  );
};

export default Loading;