import * as React from 'react'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  text?: string
  id?: string
  classNameBox: string,
  border: string,
}

export const Checkbox: React.FC<Props> = ({
  id,
  text,
  name,
  value,
  className,
  classNameBox,
  border = 'gray-200',
  checked = false,
  disabled = false,
  onChange,
  ...props
}) => {
  return (
    <label
      className={['flex relative cursor-pointer', className, disabled && 'cursor-not-allowed'].join(
        ' '
      )}
      htmlFor={id}
    >
      <input
        type="checkbox"
        className="opacity-0 absolute w-0"
        value={value}
        name={name}
        id={id}
        disabled={disabled}
        checked={checked}
        {...props}
        onChange={(e) => {
          onChange(e)
        }}
      />
      <span
        className={[
          styles.checkbox,
          checked ? styles.checked : `border-${border}`,
          classNameBox,
          disabled && styles.disabled,
        ].join(' ')}
      >
        <span />
      </span>
      <span
        className={['inline-block text-gray-800 text-sm', disabled && 'text-gray-300'].join(' ')}
      >
        {text}
      </span>
    </label>
  )
}

const styles = {
  checkbox: `
    relative
    inline-block
    appearance-none
    h-3.5
    w-3.5
    border
    focus:outline-none
    mt-1
    text-base
    align-top
    bg-no-repeat
    bg-center
    mr-2
    cursor-pointer
    rounded-sm
  `,
  checked: `
    bg-primary
    border-primary
    bg-[url('~icons/checkbox_icon.svg')]
  `,
  disabled: `
    bg-gray-100
    border-gray-200
  `,
}
