import { MarketDatum } from "@/types/marketDatum"
import { cammedFormat } from "./cammedFormat"

const formatter = new Intl.NumberFormat('ja-JP')

export const formatColumn = (marketDatum: MarketDatum, column: string): string => {
  const value = marketDatum?.[column]

  if (
    ['building_price', 'rent', 'rent_maintenance_fee_unit_price_tsubo'].includes(column) &&
    (value || value === 0)
  ) {
    return `${formatter.format(value)}円`
  } else if (column === 'prospective_yield' && value) {
    return `${formatter.format(Math.round(value * 10000) / 100)}%`
  } else if (column === 'build_age') {
    return value === 0 ? '新築' : value && `築${value}年`
  } else if (column === 'occupied_area' && value) {
    return `${cammedFormat(value, 2)}㎡`
  } else {
    return value || 'ー'
  }
}